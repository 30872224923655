import styled from 'styled-components';


export const ButtonV1 = styled.button`
    display: ${(props) => props.activestep === 0 ? 'none':'block'};
    background: #fff;
    border-width: 0px;
    color: #4C02FF;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.625rem 1rem;
    float: right;
    border: 1px solid #4C02FF;
    margin-right: 1rem;
`

export const ButtonV2 = styled.button`
    cursor: pointer;
    border: ${(props) => props.isvalid==='true' ? '1px solid #4C02FF':'1px solid #c4c4c4'};
    background: ${(props) => props.isvalid==='true' ? '#4C02FF':'#C4C4C4'};
    border-width: 0px;
    color: #fff;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.625rem 1rem;
    float: right;
`