import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import BroadcastOverviewV2 from "./BroadcastOverviewV2";
import BroadcastCreate from "../BroadcastV2/BroadcastCreateV2";

const BroadcastMarkup = (props) => {
  document.title = "SR Convert Broadcast";
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          path={`${path}/broadcast-overview`}
          exact
          component={BroadcastOverviewV2}
        ></Route>
        <Route
          path={`${path}/create-broadcast`}
          exact
          component={BroadcastCreate}
        ></Route>
        <Redirect from={`${path}`} to={`${path}/broadcast-overview`}></Redirect>
      </Switch>
    </>
  );
};
  
export default BroadcastMarkup;
