import React, { useEffect, useState } from "react";
import { getTotalSubscribersCount, isAnalyticsReady, getWhatsappCsvList, getEstimatedReachForSegments } from "../../../services/GetService";
import "../../../css/journey-css.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { Select, Tooltip } from "@material-ui/core";
import infoIcon from "../../../images/wigzolite icons/wigzolite-icons/dashboard/infoIcon.svg";
import { InfinitySpin } from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";
import CsvUploadModal from "../Modal/CsvUploadModal";
import { FormControl, MenuItem } from "@material-ui/core";
// import { isValid } from "date-fns";

const AudienceV2 = (props) => {
  const state = useSelector((state) => state);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [totalSubscribersCount, setTotalSubscribersCount] = useState(0);
  let nonShopifySeller = JSON.parse(localStorage.getItem("nonShopifySeller")) || false;
  const [selectAudience, setSelectAudience] = useState(!nonShopifySeller);
  const [predefinedUnframedSegList, setPredefinedUnframedSegList] = useState({ list: [], loading: true });
  const [predefinedTimeframedSegList, setPredefinedTimeframedSegList] = useState({ list: [], loading: true });
  const [analyticsReadyCheck, setAnalyticsReadyCheck] = useState(false);
  const [refreshanalyticsReadyCheck, setRefreshAnalyticsReadyCheck] = useState(false);
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [segmentList, setSegmentList] = useState({ list: [], loading: true });

  const [uploadedCsvList, setUploadedCsvList] = useState({ list: [], loading: true });

  const { formData, setFormData, isValid, setIsValid } = props;

  const audienceInitValues = { selectedSegment: '', segmentType: '', estimateReach: 0, selectedSegmentName: 'SEND_TO_ALL' };

  const [audience, setAudience] = useState(audienceInitValues);

  const [csvUpload, setCsvUpload] = useState({ selectCsvUpload: '', fileName: '', fileUrl: '' });
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);

  // const showUploadAudience = [13626,13855,12718].includes(state.auth.org.org_id);

  // console.log(state.auth.org.org_id);

  let addCsvPlusBtnStyle = {
    cursor: "pointer",
    border: "1px solid #4C02FF",
    // position: "absolute",
    // right: "4.5rem",
    // top: "9rem",
    background: "#4C02FF",
    borderWidth: "0px",
    color: "#fff",
    borderRadius: "5px",
    fontSize: "0.875rem",
    fontWeight: "600",
    padding: "0.625rem 1rem",
    float: "right",
    marginLeft: "1rem"
  };

  const useStyles = makeStyles(theme => ({
    select: { padding: "6px 0px 6px 0px" },
  }));
  const classes = useStyles();

  useEffect(() => {
    // isAnalyticsReady(state.auth.org.org_token).then((resJson) => {
    //   setAnalyticsReadyCheck(resJson.data.isAnalytics);
    //   if (resJson.data.isAnalytics) {
    setSegmentList({ loading: true });
    let payload = {
      org_id: state?.auth?.org?.org_id,
      channel: 'whatsapp',
      org_type: 'convert'
    };
    getEstimatedReachForSegments(payload, state.auth.org.org_token).then((response) => {
      setSegmentList({ list: response?.data?.response, loading: false });
      const index = response?.data?.response?.findIndex((segment) => segment?.id === 0);
      let selectedSegment = {};
      if (index != -1) {
        selectedSegment = response?.data?.response[index];
      }
      if((formData.targetedAudience.includes[0].selectedSegmentName==='SEND_TO_ALL')) {
        formData.targetedAudience.includes[0].estimateReach = selectedSegment?.count;
      }
      setSubscribersCount(selectedSegment?.count);
      setTotalSubscribersCount(selectedSegment?.count);
      let selectedAudience = {
        selectedSegment: formData.targetedAudience.includes[0].selectedSegment,
        selectedSegmentName: formData.targetedAudience.includes[0].selectedSegmentName,
        segmentType: 'predefined',
        estimateReach: formData.targetedAudience.includes[0].estimateReach
      };
      setAudience(selectedAudience);
      setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [formData.targetedAudience.includes[0]] } });
    },
      (error) => {
        console.log(error);
      });
    //   }
    // });

  }, [refreshanalyticsReadyCheck]);
  // const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    (formData.targetedAudience.includes[0].selectedSegmentName === "sendToAll" || csvUpload?.selectCsvUpload)
      && totalSubscribersCount === 0
      && getTotalSubscribersCount(props.formData.selectedChannel, state.auth.org.org_token).then((response) => {
        setSubscribersCount(response.data);
        setTotalSubscribersCount(response.data);
        formData.targetedAudience.includes[0].estimateReach = response.data;
        let selectedAudience = {
          // id: formData.targetedAudience.includes[0].selectedSegment,
          selectedSegment: formData.targetedAudience.includes[0].selectedSegment,
          selectedSegmentName: formData.targetedAudience.includes[0].selectedSegment,
          segmentType: formData.targetedAudience.includes[0].selectedSegment,
          estimateReach: formData.targetedAudience.includes[0].estimateReach
        };
        setAudience(selectedAudience);
        setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [formData.targetedAudience.includes[0]] } });
      });
    // props.handleButtonState(formik.isValid && formik.dirty)
  }, [audience.selectedSegmentName, csvUpload.selectCsvUpload]);

  useEffect(() => {
    // && (analyticsReadyCheck ? (segmentList.loading===false) : true)
    (!!formData.targetedAudience.includes[0].selectedSegmentName && formData.targetedAudience.includes[0].estimateReach > 0 && audience?.estimateReach > 0) ?
      setIsValid(true)
      :
      setIsValid(false)
      ;
  }, [formData.targetedAudience, audience.selectedSegmentName, audience.estimateReach, segmentList]);

  function selectSegmentType(e) {

    // let selectedSegment = JSON.parse(e.target.dataset.segmentobj);
    // formData.targetedAudience.includes[0].estimateReach = selectedSegment.count;
    // setSubscribersCount(selectedSegment.count);
    // formData.targetedAudience.includes[0].estimateReach = selectedSegment.count;

    // let selectedAudience = {
    //   selectedSegment: e.target.value === 'sendToAll' ? 'sendToAll' : selectedSegment.id,
    //   selectedSegmentName: e.target.value === 'sendToAll' ? 'sendToAll' : selectedSegment.key,
    //   segmentType: e.target.value,
    //   estimateReach: formData.targetedAudience.includes[0].estimateReach
    // };
    let selectedSegment = JSON.parse(e.target.dataset.segmentobj);
    formData.targetedAudience.includes[0].estimateReach = selectedSegment.count;
    setSubscribersCount(selectedSegment.count);

    let selectedAudience = {
      selectedSegment: selectedSegment.id,
      selectedSegmentName: selectedSegment.key,
      segmentType: e.target.value,
      estimateReach: formData.targetedAudience.includes[0].estimateReach
    };
    setAudience(selectedAudience);
    setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [selectedAudience] } });
  }

  function fetchUploadedCsvList() {
    getWhatsappCsvList(state.auth.org.org_token).then((response) => {
      setUploadedCsvList({ list: response?.data?.uploadedFiles });
    },
      (error) => {
        console.log(error);
      });
  }

  function selectCsv(e) {
    // console.log("E target Name:", e.target.name);
    if (e.target.name === 'selectCsvUpload') {
      fetchUploadedCsvList();
      setCsvUpload({ ...csvUpload, selectCsvUpload: e.target.value });
      setShowCsvUpload(false);
    }
    if (e.target.name === 'uploadNewCsv') {
      setCsvUpload({ ...csvUpload, selectCsvUpload: 0, fileUrl: '', fileName: '' });
      setShowCsvUpload(true);
    }
    setAudience(audienceInitValues);
    setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [audienceInitValues] }, onlyCsv: true });
  }

  function handleCsvChange(e) {
    let selectedSegment = e.target.value;
    let selectedAudience = {
      // id: selectedSegment.id,
      selectedSegment: selectedSegment?.url,
      selectedSegmentName: selectedSegment?.name,
      segmentType: "CSV",
      estimateReach: selectedSegment?.extraData?.validationData?.counts?.estimated_reach
    };
    setAudience(selectedAudience);
    selectedAudience = {
      ...selectedAudience,
      headers: selectedSegment?.extraData?.headers?.join(","),
      communicationIndex: null
    };
    // console.log(selectedAudience);
    setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [selectedAudience] }, onlyCsv: true });
    setCsvUpload({ ...csvUpload, fileName: selectedSegment.name, fileUrl: selectedSegment.url });
  }

  // function openCsvUploadPopup() {
  //   setShowCsvUploadModal(true);
  // }

  const toggleAudienceSelection = (e) => {
    let updatedAudience = !selectAudience;
    setSelectAudience(updatedAudience);
    let selectedAudience = {
      selectedSegment: formData.targetedAudience.includes[0].selectedSegment,
      selectedSegmentName: formData.targetedAudience.includes[0].selectedSegmentName,
      segmentType: 'predefined',
      estimateReach: formData.targetedAudience.includes[0].estimateReach
    };
    setAudience(selectedAudience);

    if (e.target.name === 'selectAudience') {
      if (formData.hasOwnProperty('onlyCsv')) {
        delete formData.onlyCsv;
      }
    }
    if (e.target.name === 'uploadAudience') {
      setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [audienceInitValues] }, onlyCsv: true });
    }
  };

  return (
    <>
      <section>
        <div style={{ paddingTop: "0rem" }} className="broadcast-channels-container d-flex">
          <form id="Audience" className="audience-left-container">
            <div className="custom-card-container p-0">
              <div className="d-flex align-items-center justify-content-between p-3 b-ddd bg-eee">
                {!nonShopifySeller && <div className="audience-radio-container mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="selectAudience"
                    id='selectAudience'
                    onChange={toggleAudienceSelection}
                    checked={selectAudience}
                  />
                  <label
                    htmlFor={'selectAudience'}>
                    <h3 className="custom-card-heading">Select Audience</h3>
                  </label>
                </div>}
                <div className="audience-radio-container m-r-14 mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    id='uploadAudience'
                    name="uploadAudience"
                    onChange={toggleAudienceSelection}
                    checked={!selectAudience}
                  />
                  <label
                    htmlFor={'uploadAudience'}>
                    <h3 className="custom-card-heading">Upload Audience</h3>
                  </label>
                </div>
                <p style={{ display: segmentList?.loading ? 'block' : 'none', color: 'red' }}>
                  {/* && !analyticsReadyCheck  */}
                  {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                  <path d="M7.38763 11.3333H8.80013V7.33334H7.38763V11.3333ZM8 6.10001C8.15556 6.10001 8.28611 6.05001 8.39167 5.95001C8.49722 5.85001 8.55 5.72223 8.55 5.56668C8.55 5.41112 8.49722 5.27779 8.39167 5.16668C8.28611 5.05557 8.15556 5.00001 8 5.00001C7.84445 5.00001 7.71389 5.05557 7.60834 5.16668C7.50278 5.27779 7.45 5.41112 7.45 5.56668C7.45 5.72223 7.50278 5.85001 7.60834 5.95001C7.71389 6.05001 7.84445 6.10001 8 6.10001ZM8 14.6667C7.08889 14.6667 6.22778 14.4917 5.41667 14.1417C4.60556 13.7917 3.89722 13.3139 3.29167 12.7083C2.68611 12.1028 2.20834 11.3945 1.85834 10.5833C1.50834 9.77223 1.33334 8.90557 1.33334 7.98334C1.33334 7.07223 1.50834 6.21112 1.85834 5.40001C2.20834 4.5889 2.68611 3.88334 3.29167 3.28334C3.89722 2.68334 4.60556 2.20834 5.41667 1.85834C6.22778 1.50834 7.09445 1.33334 8.01667 1.33334C8.92778 1.33334 9.78889 1.50834 10.6 1.85834C11.4111 2.20834 12.1167 2.68334 12.7167 3.28334C13.3167 3.88334 13.7917 4.5889 14.1417 5.40001C14.4917 6.21112 14.6667 7.07779 14.6667 8.00001C14.6667 8.91112 14.4917 9.77223 14.1417 10.5833C13.7917 11.3945 13.3167 12.1028 12.7167 12.7083C12.1167 13.3139 11.4111 13.7917 10.6 14.1417C9.78889 14.4917 8.92222 14.6667 8 14.6667ZM8.01667 13.6667C9.58334 13.6667 10.9167 13.1139 12.0167 12.0083C13.1167 10.9028 13.6667 9.56112 13.6667 7.98334C13.6667 6.41668 13.1167 5.08334 12.0167 3.98334C10.9167 2.88334 9.57778 2.33334 8 2.33334C6.43334 2.33334 5.09722 2.88334 3.99167 3.98334C2.88611 5.08334 2.33334 6.42223 2.33334 8.00001C2.33334 9.56668 2.88611 10.9028 3.99167 12.0083C5.09722 13.1139 6.43889 13.6667 8.01667 13.6667Z"
                    fill="red" />
                  <circle cx="8" cy="5.59999" r="0.8" fill="red" />
                </svg> */}
                  Your data is being setup. Please wait while we load your predefined segments.
                  <Tooltip title='Please refresh to load predefined segments'
                    arrow
                    placement="bottom">
                    <i className="fa fa-refresh"
                      onClick={() => setRefreshAnalyticsReadyCheck(!refreshanalyticsReadyCheck)}
                      style={{ marginLeft: '8px', color: '#4c02ff', cursor: 'pointer' }}></i>
                  </Tooltip>
                </p>
              </div>
              {selectAudience &&
                <div className="d-flex custom-card-body p-3">
                  <div
                    className="col-md-6"
                    style={{ padding: 0 }}
                  >
                    {/* <input type="hidden" name="audience.selectedSegment"></input>
                  <input type="hidden" name="audience.selectedSegmentName"></input>
                  <input type="hidden" name="audience.estimateReach"></input>
                  <input type="hidden" name="audience.segmentType"></input> */}

                    {/* <div className="audience-radio-container">
                      <input
                        className="form-check-input"
                        type="radio"
                        id='SendToAll'
                        value='sendToAll'
                        name="audience.segmentType"
                        onChange={selectSegmentType}
                        checked={audience.selectedSegmentName === 'sendToAll'}
                        required
                      /> */}
                    {/* <label
                        htmlFor={'SendToAll'}>
                        Send to all registered users
                      </label> */}
                    {/* <Tooltip title='Customers who have not purchased within the last 30 days, but have purchased before that.'
                            arrow
                            placement="bottom"
                            >
                            <img src={infoIcon} />
                          </Tooltip> */}
                    {/* </div> */}
                    {(segmentList?.loading) &&
                      // !!analyticsReadyCheck &&
                      <div className=" audience-radio-container" style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
                        <InfinitySpin color="#4c02ff" />
                        <span>Hold on, We are fetching segments for you.</span>
                      </div>}
                    {!segmentList?.loading && segmentList?.list?.length > 0 && segmentList?.list?.map((targetobj, i) => {
                      let keyData = (
                        <div className="audience-radio-container" key={i}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={targetobj.key}
                            value={'predefined'}
                            name="audience.segmentType"
                            onChange={selectSegmentType}
                            data-segmentobj={JSON.stringify(targetobj)}
                            checked={targetobj.key === audience.selectedSegmentName}
                            required
                          />
                          <label
                            htmlFor={targetobj.key}
                            className=" "
                          >
                            {targetobj.label}
                            {[28, 19, 23, 20].includes(targetobj?.id) && <Tooltip title={targetobj?.description || ''}
                              arrow
                              placement="bottom"
                            >
                              <img src={infoIcon} />
                            </Tooltip>}
                          </label>
                        </div>
                      );
                      return keyData;
                    })}
                    {/* {!predefinedTimeframedSegList.loading && predefinedTimeframedSegList.list.length > 0 && predefinedTimeframedSegList.list.map((targetobj, i) => {
                      let keyData = (
                        <div className="audience-radio-container" key={i}>
                          <input
                            className="form-check-input"
                            type="radio"
                            id={targetobj.key}
                            value="predefined"
                            // checked = {true}
                            name="audience.segmentType"
                            onChange={selectSegmentType}
                            data-segmentobj={JSON.stringify(targetobj)}
                            checked={audience.selectedSegmentName === targetobj.key}
                            required
                          />
                          <label
                            htmlFor={targetobj.key}
                            className=" "
                          >
                            {targetobj.label}
                            <Tooltip title={targetobj.description}
                              arrow
                              placement="bottom"
                            >
                              <img src={infoIcon} />
                            </Tooltip>
                          </label>

                        </div>
                      );
                      return keyData;
                    })} */}
                    {!segmentList?.loading && segmentList.list.length === 0 &&
                      <div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'Recently purchased in last 30 days'}
                          </label>
                        </div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'Recently purchased in last 60 days'}
                          </label>
                        </div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'Recently purchased in last 90 days'}
                          </label>
                        </div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'New Customer'}
                          </label>
                        </div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'Inactive Customers'}
                          </label>
                        </div>
                        <div className="audience-radio-container hidden-series disabled-state">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled
                          />
                          <label>
                            {'Premium Customers'}
                          </label>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
              {
                !selectAudience &&
                <div className="p-3">
                  <div
                  >
                    <div className="audience-radio-container">
                      <input
                        className="form-check-input"
                        type="radio"
                        id='selectCsvUpload'
                        value='1'
                        name="selectCsvUpload"
                        onChange={selectCsv}
                        checked={csvUpload.selectCsvUpload === '1'}
                        required
                      />
                      <label
                        htmlFor={'selectCsvUpload'} labelfor="selectCsvUpload">
                        Select Previously Uploaded File
                      </label>
                    </div>
                    {
                      !!csvUpload.selectCsvUpload &&
                      <div className="d-flex">
                        <FormControl style={{ width: '55%', marginLeft: '30px' }}>
                          <Select
                            displayEmpty
                            variant="outlined"
                            name="csv"
                            className={classes.select}
                            renderValue={csvUpload.fileName !== "" ? () => csvUpload.fileName : () => 'Select Csv'}
                            onChange={(e) => handleCsvChange(e)}
                            // error={formik.touched.selectedTemp && Boolean(formik.errors.selectedTemp)}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            {uploadedCsvList.list.length <= 0 ? (
                              <p style={{ margin: "0 25px" }}>
                                {" "}
                                'CSV List' is empty, please add CSV'.{" "}
                              </p>
                            ) : (
                              !!uploadedCsvList.list &&
                              uploadedCsvList.list.map((csv, i) => {
                                return (
                                  <MenuItem
                                    key={csv?.uuid}
                                    value={csv}
                                  >
                                    {csv?.name}
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    }
                    <div className="audience-radio-container mt-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        id='uploadNewCsv'
                        value='1'
                        name="uploadNewCsv"
                        onChange={selectCsv}
                        checked={csvUpload.selectCsvUpload == '0'}
                      />
                      <label
                        htmlFor={'uploadNewCsv'} labelfor="uploadNewCsv">
                        Upload New File
                      </label>
                    </div>
                  </div>
                  {showCsvUpload && <CsvUploadModal fetchUploadedCsvList={fetchUploadedCsvList} setAudience={setAudience} formData={formData} setFormData={setFormData} />}
                </div>
              }
            </div>
          </form>
          <div
            className="col-md-6 audience-right-container"
            style={{ padding: 0 }}>
            <div className="wigzo-reach-container">
              <h4>Estimated Reach

                <Tooltip title='This count might contain duplicate entries'
                  arrow
                  placement="bottom">
                  <img src={infoIcon} />
                </Tooltip>
              </h4>
              <h3>{audience.estimateReach}</h3>
            </div>
          </div>
        </div>
      </section>
      {/* <CsvUploadModal showModal={showCsvUploadModal} setShowModal={setShowCsvUploadModal} fetchUploadedCsvList={fetchUploadedCsvList}/> */}
    </>
  );
};

export default AudienceV2;
