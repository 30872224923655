import React, { useEffect, useState } from "react";
import { getPredefinedUnframedSegmentsList, getPredefinedTimeframedSegmentsList, getTotalSubscribersCount, isAnalyticsReady } from "../../../services/GetService";
import "../../../css/journey-css.css";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import broadcastFormData from "./FormData";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import infoIcon from "../../../images/wigzolite icons/wigzolite-icons/dashboard/infoIcon.svg";
import { InfinitySpin } from "react-loader-spinner";
const Audience = (props) => {
  const state = useSelector((state) => state);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [totalSubscribersCount, setTotalSubscribersCount] = useState(0);
  const [predefinedUnframedSegList, setPredefinedUnframedSegList] = useState({ list: [], loading: true });
  const [predefinedTimeframedSegList, setPredefinedTimeframedSegList] = useState({ list: [], loading: true });
  const [analyticsReadyCheck, setAnalyticsReadyCheck] = useState(false);
  const [refreshanalyticsReadyCheck, setRefreshAnalyticsReadyCheck] = useState(false);

  let steps = props.steps;
  let activeStep = props.activeStep;
  let isLastStep = props.steps.length - 1;
  let nextStyle = props.nextStyle;
  let disabled = props.disabled;
  const initialValue = { targetedAudience: props.isCompleted ? props.formData.targetedAudience : broadcastFormData.targetedAudience };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValue },
    validationSchema: props.currentValidationSchema,
    onSubmit: values => {
      //_handleSubmit(values)
      props.handleSubmission(values);
    }
  });

  useEffect(() => {
    isAnalyticsReady(state.auth.org.org_token).then((resJson) => {
      setAnalyticsReadyCheck(resJson.data.isAnalytics);
      if (resJson.data.isAnalytics) {
        setPredefinedUnframedSegList({ loading: true });
        getPredefinedUnframedSegmentsList(state.auth.org.org_id).then((response) => {
          setPredefinedUnframedSegList({ list: response.data, loading: false });
        });
        setPredefinedTimeframedSegList({ loading: true });
        getPredefinedTimeframedSegmentsList(state.auth.org.org_id, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("yy-MM-DD"), moment(new Date()).format("yy-MM-DD")).then((response) => {
          setPredefinedTimeframedSegList({ list: response.data, loading: false });
        });
      }
    });

  }, [refreshanalyticsReadyCheck]);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    props.formData.targetedAudience.selectedSegmentName === 'sendToAll'
      || formik.values.targetedAudience.selectedSegmentName === "sendToAll"
      && totalSubscribersCount === 0
      && getTotalSubscribersCount(props.formData.selectedChannel, state.auth.org.org_token).then((response) => {
        setSubscribersCount(response.data);
        setTotalSubscribersCount(response.data);
        props.formData.targetedAudience.estimateReach = response.data;
        formik.setFieldValue("targetedAudience.estimateReach", response.data);
      });
    // props.handleButtonState(formik.isValid && formik.dirty)
  }, [formik.isValid, formik.dirty, formik.values.targetedAudience.selectedSegmentName]);
  useEffect(() => {
    !!formik.values.targetedAudience.selectedSegmentName && formik.values.targetedAudience.estimateReach > 0 && (analyticsReadyCheck ? (predefinedTimeframedSegList.loading === false && predefinedUnframedSegList.loading === false) : true) ?
      setIsValid(true)
      :
      setIsValid(false)
      ;
    // props.handleButtonState(formik.isValid && formik.dirty)
  }, [formik.isValid, formik.dirty, formik.values.targetedAudience.selectedSegmentName, formik.values.targetedAudience.estimateReach, predefinedTimeframedSegList, predefinedUnframedSegList]);

  function selectSegmentType(e) {
    let selectedSegment = "sendToAll";
    if (e.target.value === "predefined") {
      selectedSegment = JSON.parse(e.target.dataset.segmentobj);
      props.formData.targetedAudience.estimateReach = selectedSegment.count_phone;
      setSubscribersCount(selectedSegment.count_phone);
      formik.setFieldValue("targetedAudience.estimateReach", selectedSegment.count_phone);
    } else {
      setSubscribersCount(totalSubscribersCount);
      props.formData.targetedAudience.estimateReach = totalSubscribersCount;
      formik.setFieldValue("targetedAudience.estimateReach", totalSubscribersCount);
    }
    formik.setFieldValue("targetedAudience.segmentType", e.target.value);
    formik.setFieldValue("targetedAudience.selectedSegment", e.target.value === 'sendToAll' ? selectedSegment : selectedSegment.id);
    formik.setFieldValue("targetedAudience.selectedSegmentName", e.target.value === 'sendToAll' ? selectedSegment : selectedSegment.key);
    formik.setFieldValue("targetedAudience.id", e.target.value === 'sendToAll' ? selectedSegment : selectedSegment.id);

  }
  return (
    <>
      <section>
        <div style={{ paddingTop: "0rem" }} className="broadcast-channels-container d-flex">
          <button disabled={!isValid} onSubmit={props.handleSubmission} style={isValid ? nextStyle : disabled} form={steps[activeStep].label.split(" ").join("")}> {activeStep === isLastStep ? 'Send' : 'Next'}</button>

          <form id="Audience" onSubmit={formik.handleSubmit} className="audience-left-container">
            <div className="custom-card-container">
              <h3 className="custom-card-heading">Select Audience</h3>
              <div className="d-flex custom-card-body" style={{ marginTop: '20px' }}>
                <div
                  className="col-md-12"
                  style={{ padding: 0 }}
                >
                  <input type="hidden" name="targetedAudience.selectedSegment"></input>
                  <input type="hidden" name="targetedAudience.selectedSegmentName"></input>
                  <input type="hidden" name="targetedAudience.estimateReach"></input>
                  <input type="hidden" name="targetedAudience.segmentType"></input>

                  <div className=" audience-radio-container">
                    <input
                      className="form-check-input"
                      type="radio"
                      id='SendToAll'
                      value='sendToAll'
                      name="targetedAudience.segmentType"
                      onChange={selectSegmentType}
                      checked={formik.values.targetedAudience.selectedSegmentName === 'sendToAll'}
                      required
                    />
                    <label
                      htmlFor={'SendToAll'}>
                      Send to all registered users
                    </label>
                  </div>
                  {(predefinedUnframedSegList.loading ||
                    predefinedTimeframedSegList.loading) &&
                    !!analyticsReadyCheck &&
                    <div className=" audience-radio-container" style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
                      <InfinitySpin color="#4c02ff" />
                      <span>Hold on, We are fetching segments for you.</span>
                    </div>}
                  {!predefinedUnframedSegList.loading && predefinedUnframedSegList.list.length > 0 && predefinedUnframedSegList.list.map((targetobj, i) => {
                    let keyData = (
                      <div className="audience-radio-container" key={i}>
                        <input
                          className="form-check-input"
                          type="radio"
                          id={targetobj.key}
                          value="predefined"
                          // checked = {true}
                          name="targetedAudience.segmentType"
                          onChange={selectSegmentType}
                          data-segmentobj={JSON.stringify(targetobj)}
                          checked={formik.values.targetedAudience.selectedSegmentName === targetobj.key}
                          required
                        />
                        <label
                          htmlFor={targetobj.key}
                          className=" "
                        >
                          {targetobj.label}
                          <Tooltip title={targetobj?.description || ''}
                            arrow
                            placement="bottom"
                            style={{ display: !!targetobj.show_tooltip ? 'block' : 'none' }}>
                            <img src={infoIcon} />
                          </Tooltip>
                        </label>
                      </div>
                    );
                    return keyData;
                  })}
                  {!predefinedTimeframedSegList.loading && predefinedTimeframedSegList.list.length > 0 && predefinedTimeframedSegList.list.map((targetobj, i) => {
                    let keyData = (
                      <div className="audience-radio-container" key={i}>
                        <input
                          className="form-check-input"
                          type="radio"
                          id={targetobj.key}
                          value="predefined"
                          // checked = {true}
                          name="targetedAudience.segmentType"
                          onChange={selectSegmentType}
                          data-segmentobj={JSON.stringify(targetobj)}
                          checked={formik.values.targetedAudience.selectedSegmentName === targetobj.key}
                          required
                        />
                        <label
                          htmlFor={targetobj.key}
                          className=" "
                        >
                          {targetobj.label}
                          <Tooltip title={targetobj?.description || ''}
                            arrow
                            placement="bottom"
                            style={{ display: !!targetobj.show_tooltip ? 'block' : 'none' }}>
                            <img src={infoIcon} />
                          </Tooltip>
                        </label>

                      </div>
                    );
                    return keyData;
                  })}
                  {!predefinedTimeframedSegList.loading && predefinedTimeframedSegList.list.length === 0 &&
                    <div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'Recently purchased in last 30 days'}
                        </label>
                      </div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'Recently purchased in last 60 days'}
                        </label>
                      </div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'Recently purchased in last 90 days'}
                        </label>
                      </div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'New Customer'}
                        </label>
                      </div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'Inactive Customers'}
                        </label>
                      </div>
                      <div className="audience-radio-container hidden-series disabled-state">
                        <input
                          className="form-check-input"
                          type="radio"
                          disabled
                        />
                        <label>
                          {'Premium Customers'}
                        </label>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <p style={{ display: predefinedTimeframedSegList.loading && !analyticsReadyCheck ? 'block' : 'none', color: 'red' }}>
                {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                  <path d="M7.38763 11.3333H8.80013V7.33334H7.38763V11.3333ZM8 6.10001C8.15556 6.10001 8.28611 6.05001 8.39167 5.95001C8.49722 5.85001 8.55 5.72223 8.55 5.56668C8.55 5.41112 8.49722 5.27779 8.39167 5.16668C8.28611 5.05557 8.15556 5.00001 8 5.00001C7.84445 5.00001 7.71389 5.05557 7.60834 5.16668C7.50278 5.27779 7.45 5.41112 7.45 5.56668C7.45 5.72223 7.50278 5.85001 7.60834 5.95001C7.71389 6.05001 7.84445 6.10001 8 6.10001ZM8 14.6667C7.08889 14.6667 6.22778 14.4917 5.41667 14.1417C4.60556 13.7917 3.89722 13.3139 3.29167 12.7083C2.68611 12.1028 2.20834 11.3945 1.85834 10.5833C1.50834 9.77223 1.33334 8.90557 1.33334 7.98334C1.33334 7.07223 1.50834 6.21112 1.85834 5.40001C2.20834 4.5889 2.68611 3.88334 3.29167 3.28334C3.89722 2.68334 4.60556 2.20834 5.41667 1.85834C6.22778 1.50834 7.09445 1.33334 8.01667 1.33334C8.92778 1.33334 9.78889 1.50834 10.6 1.85834C11.4111 2.20834 12.1167 2.68334 12.7167 3.28334C13.3167 3.88334 13.7917 4.5889 14.1417 5.40001C14.4917 6.21112 14.6667 7.07779 14.6667 8.00001C14.6667 8.91112 14.4917 9.77223 14.1417 10.5833C13.7917 11.3945 13.3167 12.1028 12.7167 12.7083C12.1167 13.3139 11.4111 13.7917 10.6 14.1417C9.78889 14.4917 8.92222 14.6667 8 14.6667ZM8.01667 13.6667C9.58334 13.6667 10.9167 13.1139 12.0167 12.0083C13.1167 10.9028 13.6667 9.56112 13.6667 7.98334C13.6667 6.41668 13.1167 5.08334 12.0167 3.98334C10.9167 2.88334 9.57778 2.33334 8 2.33334C6.43334 2.33334 5.09722 2.88334 3.99167 3.98334C2.88611 5.08334 2.33334 6.42223 2.33334 8.00001C2.33334 9.56668 2.88611 10.9028 3.99167 12.0083C5.09722 13.1139 6.43889 13.6667 8.01667 13.6667Z"
                    fill="red" />
                  <circle cx="8" cy="5.59999" r="0.8" fill="red" />
                </svg> */}
                Your data is being setup. Please wait while we load your predefined segments.
                <Tooltip title='Please refresh to load predefined segments'
                  arrow
                  placement="bottom">
                  <i className="fa fa-refresh"
                    onClick={() => setRefreshAnalyticsReadyCheck(!refreshanalyticsReadyCheck)}
                    style={{ marginLeft: '8px', color: '#4c02ff', cursor: 'pointer' }}></i>
                </Tooltip>
              </p>
            </div>
          </form>
          <div
            className="col-md-6 audience-right-container"
            style={{ padding: 0 }}>
            <div className="wigzo-reach-container">
              <h4>Estimated Reach

                <Tooltip title='This count might contain duplicate entries'
                  arrow
                  placement="bottom">
                  <img src={infoIcon} />
                </Tooltip>
              </h4>
              <h3>{formik.values.targetedAudience.estimateReach}</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Audience;
