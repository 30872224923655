import { isBefore, setISODay, subDays } from "date-fns";
import { TextField } from "material-ui";
import { getMuiTheme, MuiThemeProvider } from "material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DatePicker } from "rsuite";
import { number } from "yup";

const ScheduleCampaignV2 = (props) => {
  const scheduleCampaignList = [
    { key: "Send immediately ", value: "NOW" },
    { key: "Custom Date ", value: "SCHEDULED" },
    { key: "Recurring ", value: "RECURRING" },
  ];
  const {formData, setIsValid, scheduleCampaign,setScheduleCampaign} = props;

  const [initialValues,setInitialValues] = useState({
    id:null,
    action: "NOW",
    scheduleAt: null,
    repeatStopDateTime: null,
    repeatAfterDays: 1,
    predefinedSegmentData: {}
  })

  let convertToEpoch = (dt) => {
    return Math.trunc(dt / 1000);
  };
  const changeScheduleAt = (e) => {
    let epochedDT = convertToEpoch(e);
    if(scheduleCampaign.action === 'SCHEDULED') {
      setScheduleCampaign({...scheduleCampaign, scheduleAt: epochedDT*1000, repeatStopDateTime:(epochedDT+(30*60))*1000});
    }
    if(scheduleCampaign.action === 'RECURRING') {
      setScheduleCampaign({...scheduleCampaign, scheduleAt: epochedDT*1000})
    }
  };
  const changeRepeatStopDateTime = (e) => {
    let epochedDT = convertToEpoch(e);
    setScheduleCampaign({...scheduleCampaign, repeatStopDateTime:epochedDT*1000});
  };

  const handleRepeatAfterDays = (e) => {
    setScheduleCampaign({...scheduleCampaign, repeatAfterDays: e.target.value});
  };
  const shouldDisableDates = (date) => {
    let minusOneDay = subDays(new Date(), 1);
    return isBefore(date, minusOneDay);
  };
  
  let predefinedSegmentData = {
    newCustomAudienceData: {
      name: "",
      subtype: "CUSTOM",
      description: "",
      customer_file_source: "USER_PROVIDED_ONLY",
    },
    criteria: {
      criteria: {
        criteria_type: "intermediate",
        criteria_operation: "and",
        criteria: [],
      },
      fields: ["email"],
    },
    type: "predefined",
    segmentId: props.formData.targetedAudience.id,
    dates: {
      startDate: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    },
  };


  const setScheduleTypeFn = (e) => {
    let action = e.target.value;
    setScheduleCampaign({...initialValues, action: action});
    if (formData.targetedAudience.segmentType === "predefined") {
      setScheduleCampaign({...scheduleCampaign,predefinedSegmentData: predefinedSegmentData});
    }
    setIsValid(!!action);
  };

  useEffect(() => {
    if (formData.targetedAudience.segmentType === "predefined") {
      setScheduleCampaign({...scheduleCampaign,predefinedSegmentData: predefinedSegmentData});
    }
    if(scheduleCampaign.action === 'NOW') {
      setIsValid(true);
    }
    if (scheduleCampaign.action === 'SCHEDULED') {
      if(!!scheduleCampaign.scheduleAt) {
        setIsValid(true); 
      } else {
        setIsValid(false);
      }   
    }
    if(scheduleCampaign.action === 'RECURRING') {
      if(!!scheduleCampaign.scheduleAt && !!scheduleCampaign.repeatStopDateTime && !!scheduleCampaign.repeatAfterDays) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [scheduleCampaign]);
  return (
    <>
      <section>
        <div
          className="broadcast-channels-container"
        >
          <input type="hidden" name="predefinedSegmentData" value={JSON.stringify(predefinedSegmentData)} />
          <div className="d-flex">
            <form
              id="ScheduleCampaign"
              className="audience-left-container"
            >
              <div className="custom-card-container">
                <h3 className="custom-card-heading">Schedule campaign</h3>
                <div className="d-flex custom-card-body" style={{ marginTop: '20px' }}></div>
                <div style={{ padding: 0 }}>
                  {!!scheduleCampaignList &&
                    scheduleCampaignList.map((targetobj, i) => {
                      let keyData = (
                        <div
                          className="col-md-10 audience-radio-container"
                          key={i}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            id={targetobj.key + i}
                            value={targetobj.value}
                            name="action"
                            checked={scheduleCampaign?.action == targetobj.value}
                            onChange={setScheduleTypeFn}
                            required
                          />
                          <label
                            htmlFor={targetobj.key + i}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {targetobj.key}
                          </label>
                        </div>
                      );
                      return keyData;
                    })}
                </div>
              </div>
            </form>
            <div
              className="col-md-6 audience-right-container"
              style={{ padding: 0, display: 'flex', justifyContent: 'center' }}
            >
              <div
                style={{
                  display: scheduleCampaign?.action === "RECURRING" || scheduleCampaign?.action === "SCHEDULED" ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label htmlFor="scheduleAt">Schedule At</label>
                <DatePicker
                  format="dd-MM-yyyy HH:mm:ss"
                  id="scheduleAt"
                  value={scheduleCampaign.scheduleAt? new Date(scheduleCampaign?.scheduleAt):null}
                  cleanable={false}
                  placement={"bottomEnd"}
                  preventOverflow={true}
                  editable={false}
                  shouldDisableDate={shouldDisableDates}
                  onChange={changeScheduleAt}
                ></DatePicker>

                <div
                  style={{
                    display:
                      scheduleCampaign.action === "RECURRING" ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MuiThemeProvider muiTheme={getMuiTheme()}>
                    <label htmlFor="repeatAfterDays">Repeat Every (days)</label>
                    <TextField
                      type="number"
                      name="repeatAfterDays"
                      autoComplete="off"
                      className="input-field-redux"
                      label="Repeat Every (days)"
                      id="repeatAfterDays"
                      min={1}
                      defaultValue={scheduleCampaign.repeatAfterDays}
                      onChange={handleRepeatAfterDays}
                    />
                  </MuiThemeProvider>
                  <label htmlFor="repeatStopDateTime">Stop At</label>
                  <DatePicker
                    format="dd-MM-yyyy HH:mm:ss"
                    id="repeatStopDateTime"
                    cleanable={false}
                    placement={"bottomEnd"}
                    value={scheduleCampaign.repeatStopDateTime ? new Date(scheduleCampaign.repeatStopDateTime):null}
                    preventOverflow={true}
                    editable={false}
                    shouldDisableDate={shouldDisableDates}
                    onChange={changeRepeatStopDateTime}
                  ></DatePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScheduleCampaignV2;
