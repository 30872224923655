import React, { useEffect, useState } from "react";
// import { store } from "../../../../../store/store";
import { WidgetCard } from "./WidgetCard";
import _ from "lodash";
import { getOverallAnalyticsData, getBroadcastGraphData } from "../../../../../services/GetService";
import { abbreviateNumber } from "js-abbreviation-number";
import { getDataChunks, getFormattedNumbers, getCTR } from "../../../CommonApp";
import arrowIcon from "../../../../../images/wigzolite icons/wigzolite-icons/dashboard/arrow_icon.svg";
import ordersIcon from "../../../../../images/wigzolite icons/wigzolite-icons/dashboard/orders_icon.svg";
import revenueIcon from "../../../../../images/wigzolite icons/wigzolite-icons/dashboard/revenue_icon.svg";
import sendIcon from "../../../../../images/wigzolite icons/wigzolite-icons/dashboard/send_icon.svg";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { addDays, subDays, startOfDay, endOfDay } from "date-fns";
import { InfinitySpin } from "react-loader-spinner";

function DashboardAnalytics() {
    const { afterToday, allowedMaxDays, combine } = DateRangePicker;
    let [dateRange, setDateRange] = useState({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
    });
    // const state = store.getState();
    const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
    const [hiddenSeries, setHiddenSeries] = useState({ 'Revenue': false, 'Orders': false });
    const predefinedDateRanges = [
        {
            label: 'today',
            value: [startOfDay(new Date()), endOfDay(new Date())]
        },
        {
            label: 'yesterday',
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
        },
        {
            label: 'last7Days',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
        }
    ];
    const [revenue, setRevenue] = useState({
        revenueSum: "",
        revenueAll: [],
        createdAt: [],
    });
    const [orders, setOrders] = useState({
        ordersSum: "",
        ordersAll: [],
        createdAt: [],
    });
    const [isGraphLoading, setIsGraphLoading] = useState(true);
    const [graph, setGraph] = useState({
        series: [
            {
                name: "Revenue",
                data: [],
            },
            {
                name: "Orders",
                data: [],
            },
        ],
        options: {
            chart: {
                zoom: {
                    enabled: false,
                },
                id: "revenueOrderChart",
                type: "area",
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },

            stroke: {
                width: [4],
                colors: ["#E6F8FE"],
                curve: "straight",
            },
            legend: {
                show: false,
            },
            colors: ["#E6F8FE"],
            xaxis: {
                type: "text",
                categories: [],
                title: {
                    text: "Time Period",
                },
            },
            markers: {
                size: [8],
                strokeWidth: [0],
                strokeColors: ["#E6F8FE"],
                border: 0,
                colors: ["#E6F8FE"],
                hover: {
                    size: 10,
                },
            },
            yaxis: {
                title: {
                    text: "Revenue & Orders",
                },
            },
        },
    });

    const [dashboardAnalyticsData, setDashboardAnalyticsData] = useState({
        REVENUE_BY_WIGZO: { title: "Revenue", value: `0`, icon: revenueIcon },
        ORDERS_BY_WIGZO: { title: "Orders", value: '0', icon: ordersIcon },
        CAMPAIGN_SENT: { title: "Sent", value: '0', icon: sendIcon },
        AVERAGE_CTR: { title: "CTR", value: `0%`, icon: arrowIcon },
    });
    let prepareOverallWidgetData = (data) => {
        return {
            REVENUE_BY_WIGZO: { title: "Revenue", value: (orgDetails?.org_currency || 'INR') + ` ${abbreviateNumber(!!data ? data?.revenue : 0)}`, icon: revenueIcon, infoIconTitle: 'Total revenue generated by overall broadcast through Convert.' },
            ORDERS_BY_WIGZO: { title: "Orders", value: `${abbreviateNumber(!!data ? data?.orders : 0)}`, icon: ordersIcon, infoIconTitle: 'Total number of orders generated by broadcast through Convert.' },
            CAMPAIGN_SENT: { title: "Sent", value: abbreviateNumber(!!data ? data?.sent : 0), icon: sendIcon, infoIconTitle: 'Total number of broadcast campaigns sent.' },
            AVERAGE_CTR: { title: "CTR", value: `${data?.clicked && data?.sent ? getCTR(data?.sent, data?.clicked) : 0}%`, icon: arrowIcon, infoIconTitle: 'Total Click through rate for all the broadcast campaigns.' },
        };
    };

    function revenueOrdersGraph() {
        setGraph({
            series: [
                {
                    name: "Revenue",
                    data: revenue.revenueAll,
                },
                {
                    name: "Orders",
                    data: orders.ordersAll,
                },
            ],
            options: {
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    id: "revenueOrderChart",
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [2],
                    colors: ["#6350FC", "#30A8FF"],
                    curve: "straight",
                },
                legend: {
                    show: false,
                },
                colors: ["#6350FC", "#30A8FF"],
                xaxis: {
                    title: {
                        text: "Time Period",
                        offsetY: 95
                    },
                    type: "datetime",
                    categories: _.map(orders.createdAt, (dt) => {
                        return moment(dt).format("MMM Do");
                    }),
                },
                markers: {
                    size: [6],
                    strokeWidth: [0],
                    strokeColors: ["#6350FC", "#30A8FF"],
                    border: 0,
                    colors: ["#6350FC", "#30A8FF"],
                    hover: {
                        size: 8,
                    },
                },
                yaxis: {
                    title: {
                        text: "Revenue & Orders",
                    },
                    labels: {
                        formatter: function (value) {
                            var val = Math.round(value.toFixed(1));
                            if (val >= 1000) {
                                val = (val / 1000).toFixed(0) + ' K';
                            }
                            return val;
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return seriesIndex === 0 ? `${orgDetails?.org_currency} ` + (value * 1000).toFixed(2) : value;
                        }
                    }
                },
            },
        });
    }
    function toggleChartSeries(e) {
        e === 'Revenue' ? setHiddenSeries({ 'Revenue': !hiddenSeries.Revenue, 'Orders': hiddenSeries.Orders }) : setHiddenSeries({ 'Revenue': hiddenSeries.Revenue, 'Orders': !hiddenSeries.Orders });
        ApexCharts.exec('revenueOrderChart', 'toggleSeries', e);
    }

    function extractCategories(data) {
        let date = Object.keys(data?.orders || data?.revenue).sort();
        return date;
    }
    useEffect(() => {
        getOverallAnalyticsData(orgDetails?.org_id).then((res) => {
            setDashboardAnalyticsData(prepareOverallWidgetData(res.data));
        });
    }, []);
    useEffect(() => {
        setIsGraphLoading(true);
        getBroadcastGraphData(orgDetails?.org_id,
            moment(dateRange.endDate).format("YYYY-MM-DD"),
            moment(dateRange.startDate).format("YYYY-MM-DD")).then((res) => {
                let graphData = res.data;
                var extractedDate, grossRevenue, numberofOrders;
                let dateRev = Object.keys(graphData['revenue']).sort();
                let dateOrders = Object.keys(graphData['orders']).sort();
                extractedDate = extractCategories(graphData);
                grossRevenue = dateRev.map(e => {
                    return graphData.revenue[e];
                });
                numberofOrders = dateOrders.map(e => {
                    return graphData.orders[e];
                });
                setOrders({
                    ordersAll: getDataChunks(numberofOrders, 'number'),
                    ordersSum: Math.round(
                        _.sum(
                            numberofOrders
                        )
                    ),
                    createdAt: getDataChunks(extractedDate, 'date'),
                });
                setRevenue({
                    revenueAll: getDataChunks(getFormattedNumbers(grossRevenue), 'number'),
                    revenueSum: Math.round(
                        _.sum(
                            grossRevenue
                        )
                    ),
                    createdAt: getDataChunks(extractedDate, 'date'),
                });
                setIsGraphLoading(false);
            });
    }, [dateRange.startDate, dateRange.endDate]);
    useEffect(() => {
        revenueOrdersGraph();
    }, [orders.createdAt, orders.ordersAll, revenue.revenueAll, revenue.revenueSum]);

    return (
        <div className="my-2 col-md-12">
            <div className="dashboard-widgets">
                {Object.values(dashboardAnalyticsData).map((value, i) => {
                    return (
                        <WidgetCard key={i} title={value.title} count={value.value} infoIconTitle={value.infoIconTitle} icon={value.icon}></WidgetCard>
                    );
                })}
            </div>
            <div className="revenue-order-chart-container">
                <div className="chart-header">
                    <h3>Revenue & Orders</h3>

                    <DateRangePicker
                        // onClick={calendarPosition}
                        ranges={predefinedDateRanges}
                        block={true}
                        value={[dateRange.startDate, dateRange.endDate]}
                        cleanable={false}
                        placement={"bottomEnd"}
                        preventOverflow={true}
                        shouldDisableDate={combine(afterToday(), allowedMaxDays(90))}
                        editable={false}
                        onChange={(value) => {
                            setDateRange({ startDate: value[0], endDate: value[1] });
                        }}

                        format='dd-MM-yyyy'
                    ></DateRangePicker>
                </div>
                <div className="chart-body">
                    <div className="col-md-9">
                        <div style={{ display: isGraphLoading ? "flex" : "none", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
                            <InfinitySpin color="#4c02ff" />
                            <span>Hold on, We are fetching data for you.</span>
                        </div>
                        <ReactApexChart
                            options={graph.options}
                            series={graph.series}
                            type="area"
                            height={390}
                            style={{ display: !isGraphLoading ? 'block' : 'none' }}
                        />
                    </div>
                    <div className="graph-metrics col-md-3">
                        <ul className="custom-legends" style={{ display: !isGraphLoading ? "flex" : "none" }}>
                            <li className={hiddenSeries.Revenue ? 'hidden-series' : ''} onClick={() => toggleChartSeries('Revenue')} >
                                <span style={{ background: "#6350FC" }}></span>
                                <span>Revenue</span>
                            </li>
                            <h3 className={hiddenSeries.Revenue ? 'hidden-series' : ''} style={{ marginBottom: '50px' }}>{orgDetails?.org_currency} {abbreviateNumber(revenue.revenueSum)}</h3>

                            <li className={hiddenSeries.Orders ? 'hidden-series' : ''} onClick={() => toggleChartSeries('Orders')} >
                                <span style={{ background: "#30A8FF" }}></span>
                                <span>Orders</span>
                            </li>
                            <h3 className={hiddenSeries.Orders ? 'hidden-series' : ''}>{abbreviateNumber(orders.ordersSum)}</h3>
                        </ul>
                        <ul style={{ display: isGraphLoading ? "flex" : "none" }}>
                            <li>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }} >
                                    <InfinitySpin color="#4c02ff" />
                                    <span>Hold on, We are fetching data for you.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardAnalytics;