import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrgList } from "../../../services/PostsService";
import { Col, Row, ListGroup } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { OrgAction } from "../../../store/actions/AuthActions";
import { connectedPageList } from "../../../store/actions";
import { saveOrgDetailsInLocalStorage } from "../../../services/AuthService";
import _debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import "../../../css/orgList.css";
// import  Audi
function OrganizationList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let [organizationsList, setOrganizationList] = useState({
    list: [],
    loading: false,
  });
  useEffect(() => {
    setOrganizationList({ list: [], loading: true });
    // getOrgList()
    //   .then((res) => {
    //     setOrganizationList({ list: res.data.organizations, loading: false });
    //     localStorage.setItem("organizationList", JSON.stringify(res.data.organizations));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

  }, []);

  let selectOrganization = (data) => {
    saveOrgDetailsInLocalStorage(data);
    dispatch(OrgAction(data));
    history.push("/");
    window.location.href = "/";
  };

  const debounceSearch = _debounce(
    (value) => {
      if (value.length > 0) {
        let filteredData = organizationsList.list.filter((item) => {
          let isFiltered = false;
          if (item['domain'].includes(value)) { isFiltered = true; }
          return isFiltered;
        });
        setOrganizationList({ list: filteredData });
      } else {
        setOrganizationList({ list: JSON.parse(localStorage.getItem('organizationList')), loading: false });

      }
    },
    700,
    []
  );


  return (
    <>
      <div>
        {organizationsList.loading ? (
          <div style={{ display: "flex" }}>
            {/* <Audio
        height="100"
        width="100"
        color='#4C02FF'
        ariaLabel='loading'
      /> */}
          </div>
        ) : (
          <>
            <div className="parentOrg">
              <div className="basic-list-group ">
                <ListGroup
                  className="listGroup"
                  as={"ul"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className="text-center">Domain manager</p>
                  <input
                    type="search"
                    id="domain"
                    className="searchOrg text-center"
                    onKeyUp={(e) => {
                      debounceSearch(e.target.value);
                    }}
                    placeholder="search domain"
                    autoComplete="false"
                    style={{ marginTop: "15px" }}
                  />
                  <ul className="orgListContent">
                    {organizationsList.list.map((list, i) => {
                      return (
                        <Fragment key={i}>
                          <ListGroup.Item
                            as={"li"}
                            className={
                              "org-list list-item  d-flex align-items-center justify-content-between"
                            }
                            style={{ marginTop: "20px", border: "none" }}
                          >
                            <div
                              onClick={() => selectOrganization(list)}
                              className="orgInfo d-flex align-items-center justify-content-center'"
                            >
                              <div className="d-flex">
                                <img
                                  className={"orgLogo mr-3"}
                                  src={list.org_icon_url}
                                />
                                <div>
                                  <h6
                                    className="orgName m-0 text-black"
                                    style={{
                                      width: "110px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {list.org_name}
                                  </h6>
                                  <p
                                    className="text-black domainName"
                                    style={{
                                      width: "110px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {list.domain}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </ListGroup.Item>
                        </Fragment>
                      );
                    })}
                  </ul>
                </ListGroup>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="overlay"></div> */}

    </>
  );
}

export default OrganizationList;
