const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
    api_token: "",
    profile_picture: "",
    status: "",
    user_email: "",
    user_name: "",
    onboarding_status: false
  },
  org: {
    domain: "",
    org_token: "",
    org_icon_url: "",
    org_name: "",
    org_id: "",
  },
  userDetails: {
    status: "",
    user_name: "",
    user_email: "",
    profile_picture: "",
    api_token: "",
    allow_checkout: "",
    allow_engage: "",
    allow_engage_push_order: "",
    allow_fbs: "",
    allow_rb_panel: "",
    allow_sr_convert: "",
    sr_jwt: "",
    channel_list: [
      {
        'active_store_details': {
          'id': '',
          'name': '',
          'store_url': '',
          'brand_name': '',
          'brand_logo': ''
        },
        'status': '',
        'permissions': '',
        'redirect_url': ''

      }

    ]
  },
  walletDetails: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === "[signup action] confirmed signup") {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === "[login action] confirmed login") {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === "user detail save") {
    return {
      ...state,
      userDetails: action.payload,
      errorMessage: "",
      successMessage: "User Details Saved Successfully",
      showLoading: false,
    };
  }

  if (action.type === "[Logout action] logout action") {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
        api_token: "",
      },
      org: {
        org: {
          domain: "",
          org_token: "",
          org_icon_url: "",
          org_name: "",
        },
      },
    };
  }

  if (
    action.type === "[signup action] failed signup" ||
    action.type === "[login action] failed login"
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === "[Loading action] toggle loading") {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  if (action.type === "[Org Action] Organization Selected") {
    return {
      ...state,
      org: action.payload,
      successMessage: "Organization Selected",
      errorMessage: "",
      showLoading: false,
    };
  }
  if (action.type === "Wallet Details") {
    return {
      ...state,
      walletDetails: action.payload,
      successMessage: "Organization Selected",
      errorMessage: "",
      showLoading: false,
    };
  }
  if(action.type === 'Show Loader') {
    return {
      ...state,
      loading: true,
    }
  }

  if(action.type === 'Hide Loader') {
    return {
      ...state,
      loading: false,
    }
  }
  return state;
}
