import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import BroadcastOverviewV2List from "./BroadcastOverviewV2List";
import BroadcastV2Create from "../BroadcastV2/BrodcastV2Create";

const BroadcastV2 = (props) => {
  document.title = "SR Convert BroadcastV2";
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          path={`${path}/broadcastV2-overview`}
          exact
          component={BroadcastOverviewV2List}
        ></Route>
        <Route
          path={`${path}/create-broadcastV2`}
          exact
          component={BroadcastV2Create}
        ></Route>
        <Redirect from={`${path}`} to={`${path}/broadcastV2-overview`}></Redirect>
      </Switch>
    </>
  );
};
  
export default BroadcastV2;
