import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WIGZO_HOST,
});

axiosInstance.interceptors.request.use((config) => {
    window.getCookie = function (name) {
      var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
      if (match) return match[2];
    };
    const state = store.getState();
    config.params = config.params || {};
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = "application/json";
    config.headers["x-wigzo-host"] = process.env.REACT_APP_WIGZO_HOST;
    config.headers["x-auth"] = state.auth.auth.api_token;
    config.headers["AUTHID"] = state.auth.auth.api_token;
    config.headers["Accept"] = "application/json";
    config.headers["SessionId"] = window.getCookie("AUTHID");
  // console.log(config);
    return config;
});

export default axiosInstance;
