import React, { useEffect, useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Deshboard
import Home from "./components/Dashboard/Home/Home";

/// Pages
import BroadcastMarkup from "./components/BroadcastV2/Broadcast";
import BroadcastV2 from "./components/BroadcastV2/BrodcastV2";
import JourneyMarkup from "./components/Dashboard/Home/Journey";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import LandingPage from "./pages/LandingPage";
import ChannelList from "./pages/ChannelList";
import ThankYouPage from "./pages/ThankYouPage";

const Markup = (props) => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// pages
    { url: "broadcast", component: BroadcastMarkup },
    { url: "broadcastV2", component: BroadcastV2 },
    { url: "journeys", component: JourneyMarkup },
    { url: "thankyou", component: ThankYouPage },
    { url: "welcome", component: LandingPage },
    { url: "channellist", component: ChannelList },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show menu-toggle" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "" : ""
            }`}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              {/* <Redirect from='/' to='dashboard'></Redirect> */}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
