import axios from "axios";
import axiosInstance from "./AxiosInstance";
import axiosInstanceQA from './AxiosInstanceQA';
import { store } from "../store/store";
import { broadcastFormDataAction } from "../store/actions/PostActions";

// export async function getRevenue(token, startDate, endDate) {
//   return await axiosInstance.get(
//     `/wigzo/lite/dashboard/metrics/${token}/performance?end_date=${endDate}&platform=shopify&start_date=${startDate}`
//   );
// }
export async function getOrders(token, startDate, endDate) {
  return await axiosInstance.get(
    `/wigzo/lite/dashboard/metrics/${token}/performance?end_date=${endDate}&platform=shopify&start_date=${startDate}`
  );
}
export async function getAbandoned(token) {
  return await axiosInstance.get(
    `/wigzo/lite/dashboard/metrics/${token}/abandon_cart?duration=all`
  );
}
export async function getRevenueByWigzo(token) {
  return await axiosInstance.get(
    `/wigzo/lite/dashboard/metrics/${token}/revenue_growth?duration=week`
  );
}
export async function getCampaignList(
  startDate,
  endDate,
  channel,
  status,
  orgToken,
  page,
  perPage,
  campaignName
) {
  return await axiosInstance.get(
    `/broadcast/campaigns?campaignName=${campaignName}&channel=${channel === "All" ? "" : channel}&endDate=${endDate}&page=${page - 1}&perpage=${perPage}&startDate=${startDate}&status=${status}&orgToken=${orgToken}`
  );
}

export async function getCampaignV2List(
  startDate,
  endDate,
  channel,
  status,
  orgToken,
  page,
  perPage,
  campaignName
) {
  return await axiosInstance.get(
    `/broadcast/campaigns?v=2&campaignName=${campaignName}&channel=${channel === "All" ? "" : channel}&endDate=${endDate}&page=${page - 1}&perpage=${perPage}&startDate=${startDate}&status=${status}&orgToken=${orgToken}`
  );
}

export async function deleteCampaign(uuid, orgToken) {
  return await axiosInstance.delete(
    `/wigzo/lite/campaign/${uuid}?orgToken=${orgToken}`
  );
}
export async function getWhatsappTemplates(page, perPage, orgToken, category, status, searchKeyword) {
  return await axiosInstance.get(
    `/whatsapp/templates?pageNum=${page - 1}&pageLimit=${perPage}&templateStatus=${status}&templateName=${searchKeyword}&orgToken=${orgToken}`
  );
}
export async function getWhatsappTemplateByUuid(orgToken, uuid) {
  return await axiosInstance.get(
    `/whatsapp/template/${uuid}?orgToken=${orgToken}`
  );
}
export async function getAllUnpagedWhatsappTemplates(orgToken, category) {
  return await axiosInstance.get(
    `/whatsapp/templates?&orgToken=${orgToken}`
  );
}
export async function refreshWhatsappStatus(orgToken) {
  return await axiosInstance.get(
    `/whatsapp/status?orgToken=${orgToken}`
  );
}
export async function deleteWhatsAppTemplate(uuid, orgToken) {
  return await axiosInstance.delete(
    `/whatsapp/template/${uuid}?orgToken=${orgToken}`
  );
}
export async function saveWhatsAppTemplate(formData, orgToken) {
  return await axiosInstance.post(
    `/whatsapp/template?orgToken=${orgToken}&apply=true`,
    formData
  );
}
export async function getSMSTemplates(page, perPage, orgToken) {
  return await axiosInstance.get(
    `/sms/template?page=${page - 1}&perpage=${perPage}&orgToken=${orgToken}`
  );
}
export async function deleteSMSTemplate(uuid, orgToken) {
  return await axiosInstance.delete(
    `/sms/template/${uuid}?orgToken=${orgToken}`
  );
}
export async function saveSMSTemplate(formData, orgToken) {
  return await axiosInstance.post(
    `/sms/template?orgToken=${orgToken}`,
    formData
  );
}
export async function saveCampaign(formData, orgToken) {
  let data = { ...formData };
  return await axiosInstance.post(
    `/broadcast/campaign/save?orgToken=${orgToken}`,
    data
  );
}

export async function saveCampaignV2(formData, orgToken) {
  let data = { ...formData };
  return await axiosInstance.post(
    `/broadcast/v2/campaign?orgToken=${orgToken}`,
    data
  );
}

export async function startCampaignV2(formData,orgToken) {
  let data = { ...formData };
  return await axiosInstance.post(
    `/broadcast/v2/start?orgToken=${orgToken}`,
    data
  );
}

export async function getTotalSubscribersCount(channel, orgToken, segmentType) {
  return await axiosInstance.post(`/broadcast/totalsubscriberscount/${channel}?orgToken=${orgToken}&datatype=${segmentType}`);
}
export async function getReportGraph(uuid, startDate, endDate) {
  return await axiosInstance.get(
    `/wigzo/lite/getallrules/${uuid}/${startDate}/${endDate}`
  );
}
export async function getReportCounts(token, channel) {
  return await axiosInstance.get(
    `wigzo/lite/getallrulesreports/${token}/${channel}`
  );
}
export async function getReportTemplates(channel, token) {
  return await axiosInstance.get(
    `/wigzo/lite/eventserver/getallrules/${channel}?orgToken=${token}`
  );
}
export async function deleteRecipe(uuid, orgToken) {
  return await axiosInstance.delete(
    `/wigzo/lite/eventserver/rule/${uuid}?orgToken=${orgToken}`
  );
}
export async function getServiceProvider(orgToken) {
  return await axiosInstance.get(
    `/whatsapp/provider?orgToken=${orgToken}`
  );
}
export async function getGateways(orgToken) {
  return await axiosInstance.get(
    `/sms/gateways?orgToken=${orgToken}`
  );
}
export async function getOrgCurrency(orgToken) {
  return await axiosInstance.get(
    `/rest/v1/broadcast/getorgcurrency?orgToken=${orgToken}`
  );
}
export async function getShortenUrl(url, orgToken) {
  return await axiosInstance.get(
    `/broadcast/shortenurl?longUrl=${btoa(url)}&orgToken=${orgToken}`
  );
}
export async function fetchAllPushTemplates(orgToken) {
  return await axiosInstance.get(
    `/rest/v1/push/alltemplate?orgToken=${orgToken}`
  );
}
export async function previewWigzoLiteRecipe(orgToken, channel, type, recipeUuid, tempName, discountDataEncoded) {
  return await axiosInstance.get(
    `/wigzo/lite/automation-recipe/preview?orgToken=${orgToken}&channel=${channel}&type=${type}&recipeUuid=${recipeUuid}&tempName=${tempName}&discountData=${discountDataEncoded}`
  );
}
export async function getPredefinedUnframedSegmentsList(orgId) {
  return await axiosInstance.post(
    `/analytics/rest/v1/run_query/org/${orgId}/customers/segments/predefined/unframed_customer_count`,
    {
      "platform": "LITE"
    }
  );
}
export async function getPredefinedTimeframedSegmentsList(orgId, startDate, endDate) {
  return await axiosInstance.post(
    `/analytics/rest/v1/run_query/org/${orgId}/customers/segments/predefined/timeframed_customer_count?end_date=${endDate}&start_date=${startDate}`,
    {
      "platform": "LITE"
    }
  );
}
export async function checkSRPermissions(jwt) {
  return await axiosInstance.get(
    `/wigzo/lite/checkpermissions/${jwt}`
  );
}

export async function getWhatsappCsvList(orgToken) {
  return await axiosInstance.get(
    `/rest/v1/broadcast/getuploadedfiles/whatsapp?orgToken=${orgToken}`
  )
}

export async function uploadWhatsappCSV(data,orgToken) {
  console.log(data);
  return await axiosInstance.post(
    `/rest/v1/uploadcsvfile/wzbroadcast/whatsapp?orgToken=${orgToken}`,
    data
  )
}

// get all estimated reach
export async function getEstimatedReachForSegments(data,orgToken) {
  return await axiosInstance.post(
    `/broadcast/v2/estimate-reach`,
    data
  )
}

export async function validateCsv(data, orgToken) {
  // /broadcast/v2/validate/csv
  return await axiosInstance.post(
    `/broadcast/v2/validate/csv?orgToken=${orgToken}`,
    data
  )
}

export function resetBroadcastActionForm(dispatch) {
  let broadcastFormData = {
    selectedChannel: "",
    campaignName: "",
    selectedTemp: "",
    selectedTempName: "",
    status: "",
    repeatAfterDays: 1,
    message: "",
    variables: [],
    replacedVariables: {},
    isUnicode: false,
    crmName: "",
    coupon: "",
    mediaUrl: "",
    targetedAudience: {
      segmentType: "sendToAll",
      selectedSegment: null,
    },
    removeDuplicates: true,
    mapped_headers: [],
    csvDetails: {},
    isArabianText: false,
    addToContactList: false,
    isScheduled: false,
    scheduleAt: '',
    repeatStopDateTime: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
    expireAfter: '',
    replacedButtonUrl: [],
    replacedAttachment: "",
    broadcastTemplateUuid: "",
    broadcastType: "BROADCAST",
    predefinedSegmentData: {},
    testSizeRatio: 50,
  };
  dispatch(broadcastFormDataAction(broadcastFormData));
}
export async function getImagesList(orgToken) {
  return await axiosInstance.get(
    `/rest/v1/account/imagegallery?orgToken=${orgToken}`
  );
}
export async function deleteGalleryImage(orgToken, imageMediaFileIds) {
  let axiosInstanceFormUrlEncoded = axios.create({
    baseURL: process.env.REACT_APP_WIGZO_HOST,
  });
  axiosInstanceFormUrlEncoded.interceptors.request.use((config) => {
    window.getCookie = function (name) {
      var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
      if (match) return match[2];
    };
    const state = store.getState();
    config.params = config.params || {};
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    config.headers["x-wigzo-host"] = process.env.REACT_APP_WIGZO_HOST;
    config.headers["x-auth"] = state.auth.auth.api_token;
    config.headers["AUTHID"] = state.auth.auth.api_token;
    config.headers["Accept"] = "*/*";
    config.headers["SessionId"] = window.getCookie("AUTHID");
    console.log(config);
    return config;
  });
  return await axiosInstanceFormUrlEncoded.post(
    `/api/v1/imagegallery/delete?orgToken=${orgToken}`,
    imageMediaFileIds);
}
export async function uploadGalleryImages(orgToken, files) {
  let axiosInstanceMultipartFormData = axios.create({
    baseURL: process.env.REACT_APP_WIGZO_HOST,
  });
  axiosInstanceMultipartFormData.interceptors.request.use((config) => {
    window.getCookie = function (name) {
      var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
      if (match) return match[2];
    };
    const state = store.getState();
    config.params = config.params || {};
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = "multipart/form-data;";
    config.headers["x-wigzo-host"] = process.env.REACT_APP_WIGZO_HOST;
    config.headers["x-auth"] = state.auth.auth.api_token;
    config.headers["AUTHID"] = state.auth.auth.api_token;
    config.headers["Accept"] = "application/json";
    config.headers["SessionId"] = window.getCookie("AUTHID");
    console.log(config);
    return config;
  });
  let formData = new FormData();
  formData.append('File', files);
  return await axiosInstanceMultipartFormData.post(`/rest/v1/account/imagegallery/upload?orgToken=${orgToken}`, formData);
}
export async function isAnalyticsReady(orgToken) {
  return await axiosInstance.get(
    `/wigzo/lite/isanalyticsready?orgToken=${orgToken}`
  );
}
export async function updateTemplateStatusByUUID(orgToken, uuid, templateStatus) {
  return await axiosInstance.get(
    `/whatsapp/updatestatus/${uuid}/${templateStatus}?orgToken=${orgToken}`
  );
}
export async function getOverallAnalyticsData(orgId) {
  return await axiosInstance.get(`/analyticsdashboard/broadcast/overall/${orgId}`);
}
export async function getBroadcastGraphData(orgId, endDate, startDate) {
  return await axiosInstance.get(`/analyticsdashboard/broadcast/graph/${orgId}?end_date=${endDate}&start_date=${startDate}`, {
    timeout: 70000 // 40 seconds
  });
}
export async function createJobForSrBi(orgToken) {
  return await axiosInstance.post(
    `/wigzo/lite/sync/org/jobs?orgToken=${orgToken}`,
    {}
  );
}