import React, { useEffect } from 'react';
import { Row, Card, Col, Button, Modal } from "react-bootstrap";
import PhonePreviewWhatsapp from './PhonePreviewWhatsapp';

export default function MessagePreviewModal(props) {
    useEffect(() => {
        if (props.modalCentered === true) {
            document.querySelector('.modal-backdrop').addEventListener('click', () => {
                props.closeModal();
            });
        }

    }, [props.modalCentered]);


    return (
        <Modal id="modalOverlay" className="previewModalTitle fade TC-modal" show={props.modalCentered}>
            <Modal.Header className='modal-content modal-header display-flex align-items-center'>
                <Modal.Title>Preview</Modal.Title>
                <Button
                    variant=""
                    style={{ color: "rgb(76, 2, 255)" }}
                    className="close"
                    onClick={() => props.setModalCentered(false)}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body >
                <PhonePreviewWhatsapp message={props.message} attachment={props.attachment} replacedButtonUrl={props.replacedButtonUrl} org_name={props.org_name} footer={props.footer} />
            </Modal.Body>
        </Modal>
    );
}
