import axios from "axios";
import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";
import { showLoader, hideLoader, userDetailAction, walletDetailsAction } from "../store/actions/AuthActions";
import {
  loginConfirmedAction,
  logout,
  OrgAction,
  // fbAuthAction,
} from "../store/actions/AuthActions";

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  let config = {
    headers: {
      "x-wigzo-host": process.env.REACT_APP_WIGZO_HOST,
    },
  };

  return axios.post(
    `${process.env.REACT_APP_WIGZO_HOST}/wigzo/lite/auth/login`,
    postData,
    config
  );
}

export function formatError(errorResponse) {
  let errorMsg =
    !!errorResponse && !!errorResponse.error && !!errorResponse.error.message
      ? errorResponse.error.message
      : errorResponse;
  switch (errorMsg) {
    case "EMAIL_EXISTS":
      //return 'EmaCORSil already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";
    default:
      return "Invalid Email or Password";
  }
}
export function saveAuthDetailsInLocalStorage(tokenDetails) {
  let date = new Date();
  let aWeekLaterDate = new Date(date.setDate(date.getDate() + 7));
  !!tokenDetails && !tokenDetails.expireDate &&
    (tokenDetails.expireDate = aWeekLaterDate);
  localStorage.setItem("authDetails", JSON.stringify(tokenDetails));
}

export function saveUserDetailsInLocalStorage(tokenDetails) {
  let date = new Date();
  let aWeekLaterDate = new Date(date.setDate(date.getDate() + 7));
  !!tokenDetails && !tokenDetails.expireDate &&
    (tokenDetails.expireDate = aWeekLaterDate);
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function saveFbTokenInLocalStorage(token) {
  localStorage.setItem("token", token);
}

export function saveOrgDetailsInLocalStorage(orgDetails) {
  localStorage.setItem("orgDetails", JSON.stringify(orgDetails));
}
export function saveWalletBalanceInLocalStorage(walletDetails) {
  localStorage.setItem("walletDetails", JSON.stringify(walletDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function isOrgSelected(dispatch) {
  let orgDetails = localStorage.getItem("orgDetails");
  !!orgDetails && (orgDetails = JSON.parse(orgDetails));
  !!orgDetails && dispatch(OrgAction(orgDetails));
  return !!orgDetails;
}

export function dispatchLoginActionAndStartTimer(
  dispatch,
  history,
  tokenDetails,
  expireDate,
  todaysDate
) {
  dispatch(loginConfirmedAction(tokenDetails));
  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
export function clearLocalStorageForSrLogin() {
  let userDetails = localStorage.getItem('userDetails');
  let orgDetails = localStorage.getItem('orgDetails');
  let authDetails = localStorage.getItem('authDetails');
  let walletDetails = localStorage.getItem('walletDetails');
  !!userDetails && localStorage.removeItem('userDetails');
  !!orgDetails && localStorage.removeItem('orgDetails');
  !!authDetails && localStorage.removeItem('authDetails');
  !!walletDetails && localStorage.removeItem('walletDetails');
}

var apiResDummy = {
  "auth": {
    "status": "success",
    "user_name": "pankaj+8579@wigzo.com",
    "user_email": "pankaj@wigzo.com",
    "profile_picture": "https://storage.googleapis.com/media.shipconvert.com/uploads/XKOhTAxmQO6rgGelmDRqsw/avatar/73dc1f7a-05a5-48a7-abfb-cdcc39735d0d.jpeg?_=2023-01-18T18:14:39.261Z",
    "api_token": "f48535d92f3f4b50bc6b4533d89d90e7",
    "is_wigzo_admin": true,
    "onboarding_status": true
  },
  "org": {
    "domain": "pannkhd.myshopify.com",
    "org_token": "Is3cY0JETAyocp7DDN9KqQ",
    "org_icon_url": "https://storage.googleapis.com/media.shipconvert.com/uploads/Is3cY0JETAyocp7DDN9KqQ/icon/c5141d2a-2914-437a-a066-82f03627425a.png?_=2023-01-18T18:21:33.620Z",
    "org_name": "Pannkh",
    "org_id": 9074,
    'permissions': 'notgranted',
  },
  "userDetails": {
    status: "",
    user_name: "",
    user_email: "",
    profile_picture: "",
    api_token: "",
    allow_checkout: "",
    allow_engage: "",
    allow_engage_push_order: "",
    allow_fbs: "",
    allow_rb_panel: "",
    allow_sr_convert: "",
    sr_jwt: "",
    channel_list: [
      {
        'active_store_details': {
          'id': '2740772',
          'name': 'SHOPIFY mc',
          'store_url': 'https://shady-gators.myshopify.com',
          'brand_name': 'Shadygators',
          'brand_logo': 'https://kr-shipmultichannel.s3.ap-southeast-1.amazonaws.com/772376/channel_logos/52465ab6-43b4-4618-a412-521e0eb57e68.png'
        },
        'status': 'active',
        'permissions': 'granted',
        'redirect_url': ''

      }

    ]
  },


};
function handleSRRedirection(props) {
  let orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
  let authDetails = JSON.parse(localStorage.getItem('authDetails'));
  if (authDetails.onboarding_status === false) {
    props.history.push('/welcome');
  } else if (orgDetails?.permissions !== 'granted' && authDetails?.onboarding_status === true && !JSON.parse(localStorage.getItem("nonShopifySeller"))) {
    props.history.push('/channellist');
  } else if (props?.location?.pathname.includes('thankyou') && orgDetails?.permissions === 'granted') {
    props.history.push('/thankyou');
  } else {
    props.history.push('/dashboard');
  }
}
export function setWalletBalance(dispatch, orgToken) {
  getWalletBalance(orgToken).then((res) => {
    dispatch(walletDetailsAction(res.data));
    saveWalletBalanceInLocalStorage(res.data);
    // document.getElementById("walletDetails").innerText = !!res.data.balance ? res.data.balance : 0;
  }).catch(error => {
    dispatch(walletDetailsAction({
      "walletUuid": "",
      "balance": 0,
      "currency": "",
      "status": ""
    }
    ));
    saveWalletBalanceInLocalStorage({
      "walletUuid": "",
      "balance": 0,
      "currency": "",
      "status": ""
    });
  });
}
// export function updateWalletBalance(dispatch, orgToken, orgPermission, onboarding_status) {
//   orgPermission === 'granted' && onboarding_status === true && deductWalletBalance(orgToken).then((response) => {
//     console.log(response.data.message);
//     // getWalletBalance(orgToken).then((res) => {
//     //   dispatch(walletDetailsAction(res.data));
//     //   saveWalletBalanceInLocalStorage(res.data);
//     //   // document.getElementById("walletDetails").innerText = !!res.data.balance ? res.data.balance : 0;
//     // });
//     setWalletBalance(dispatch, orgToken);
//   }).catch(error => {
//     console.log('Balance not deducted');
//   });
//   setWalletBalance(dispatch, orgToken);
// }
function saveLoginData(dispatch, props, response, status, path) {
  clearLocalStorageForSrLogin();
  saveUserDetailsInLocalStorage(response.data.userDetails);
  saveOrgDetailsInLocalStorage(response.data.org);
  saveAuthDetailsInLocalStorage(response.data.auth);
  localStorage.setItem("nonShopifySeller", response.data.nonShopifySeller);
  dispatch(loginConfirmedAction(response.data.auth));
  dispatch(OrgAction(response.data.org));
  dispatch(userDetailAction(response.data.userDetails));
  // updateWalletBalance(dispatch, response?.data?.org.org_token, response?.data?.org.permissions, response?.data?.auth?.onboarding_status);
  if(status == 'new'){
    props.history.push(path);
  }else{
    handleSRRedirection(props);
  }
}
export function updateOnboardingStatus() {
  let orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
  let authDetails = JSON.parse(localStorage.getItem('authDetails'));
  axiosInstance.post(`/wigzo/lite/isconsent/given?orgToken=${orgDetails.org_token}`, {}).then((res) => console.log(res));
  authDetails.onboarding_status = true;
  saveAuthDetailsInLocalStorage(authDetails);
}
export function checkSRLogin(dispatch, props, token, status, path) {
  dispatch(showLoader());
  let SRJWT = props?.location?.pathname?.replace('/wigzo/lite/auth/shiprocket/login/', '');
  status !== "new" && props.location.pathname.includes('/wigzo/lite/auth/shiprocket/login/') && getSSODetails(SRJWT).then((response) => {
    saveLoginData(dispatch, props, response);
    dispatch(hideLoader());
  })
  .catch((error) => {
    dispatch(hideLoader());
  });
  status === "new" && getSSODetails(token).then((response) => {
    saveLoginData(dispatch, props, response, "new", path);
    dispatch(hideLoader());
  })
  .catch((error) => {
      dispatch(hideLoader());
      dispatch(logout(props.history));
  });
  if (status !== "new" && props.location.pathname.includes('thankyou')) {
    let orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
    !!orgDetails ? getLoginDetailsByOrgId(orgDetails.org_id).then((res) => {
      saveLoginData(dispatch, props, res);
    }) : checkAutoLogin(dispatch, props.history);
  }
}
export function checkAutoLogin(dispatch, history) {
  const authDetailsStr = localStorage.getItem("authDetails");
  const orgDetailsStr = localStorage.getItem("orgDetails");
  const userDetailsStr = localStorage.getItem("userDetails");
  const walletDetailsStr = localStorage.getItem("walletDetails");
  let orgDetails, authDetails, userDetails, walletDetails;
  !!orgDetailsStr && (orgDetails = JSON.parse(orgDetailsStr));
  !!userDetailsStr && (userDetails = JSON.parse(userDetailsStr));
  !!walletDetailsStr && (walletDetails = JSON.parse(walletDetailsStr));
  if ((!authDetailsStr || !orgDetails) && history.location.search.search('wib') > 0) {
    setTimeout(() => {
      checkAutoLogin(dispatch, history);
    }, 1000);
    return;
  } else if (!authDetailsStr || !orgDetails) {
    dispatch(logout(history));
    return;
  }
  authDetails = JSON.parse(authDetailsStr);
  let expireDate = new Date(authDetails.expireDate);
  let todaysDate = new Date();
  if (todaysDate > expireDate && history.location.search.search('wib') > 0) {
    checkAutoLogin(dispatch, history);
  } else if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  } else {
    dispatch(loginConfirmedAction(authDetails));
    dispatch(OrgAction(orgDetails));
    dispatch(userDetailAction(userDetails));
    dispatch(walletDetailsAction(walletDetails));
    // updateWalletBalance(dispatch, orgDetails.org_token, orgDetails.permissions, authDetails.onboarding_status);
    /*dispatch(loginConfirmedAction(authDetails));
        const timer = expireDate.getTime() - todaysDate.getTime();
        runLogoutTimer(dispatch, timer, history);*/
    dispatchLoginActionAndStartTimer(
      dispatch,
      history,
      authDetails,
      expireDate,
      todaysDate
    );

    //history.push();
  }
  //checkOrg()
}
export async function getJourneyApi(token) {
  return await axiosInstance.get(`/dashboard/v6/journey/recipes?org=${token}`);
}
export async function postJourneyApi(token, readableName) {
  return await axiosInstance.post(
    `/dashboard/v6/wigzoLite/recipes/create/${readableName}?org=${token}`,
    {}
  );
}

export async function getRulesList(token, page, perpage, keyword, status) {
  return await axiosInstance.get(
    `/wigzo/lite/eventserver/rule?orgToken=${token}&page=${page - 1}&perpage=${perpage}&keyword=${keyword}&status=${status}`
  );
}

export async function toggleRuleStatus(uuid, token) {
  return await axiosInstance.get(
    `/wigzo/lite/eventserver/togglerulestatus/${uuid}?page=0&perpage=5&orgToken=${token}`
  );
}
// export async function getDashboardData(token, platform, startDate, endDate) {
//   return await axiosInstance.get(
//     `/wigzo/lite/dashboard/metrics/${token}?end_date=${endDate}T14%3A25%3A51.206Z&platform=${platform}&start_date=${startDate}05T14%3A25%3A51.206Z`
//   );
// }
export async function getSSODetails(jwt) {
  return await axiosInstance.get(`/wigzo/lite/auth/shiprocket/login/${jwt}`);
}
export async function getLoginDetailsByOrgId(orgId) {
  return await axiosInstance.get(`/wigzo/lite/auth/shiprocket/loginbyorgid/${orgId}`);
}
export async function getWalletBalance(orgToken) {
  return await axiosInstance.get(
    `/wallet/rest/v1/balance?orgToken=${orgToken}`
  );
}
// export async function deductWalletBalance(orgToken) {
//   return await axiosInstance.get(
//     `/wigzo/lite/debit/srwallet?orgToken=${orgToken}`
//   );
// }