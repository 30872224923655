export const broadcastChannels = [
  { key: "Whatsapp", value: "whatsapp" },
  // { key: "SMS", value: "sms" },
  // { key: "Email", value: "email" },
  // { key: "Push", value: "push" },
  { key: "Onsite", value: "onsitepush" },
];
export const campaignStatus = [
  { key: "ALL", label: "All"},
  { key: "DRAFT", label: "Draft"},
  { key: "RUNNING", label: "Sent" },
  { key: "RECURRING", label: "Recurring" },
  { key: "SCHEDULED", label: "Scheduled" },
  { key: "PROCESSING", label: "Processing" },
  // { key: "ERROR", label: "Error"},
  { key: "STOPPED", label: "Paused"},
  // { key: "ARCHIVED" , label: "Archived"}
  // { key: "WAITING", label: "Waiting" },
];

export const campaignStatusV2 = [
  { key: "ALL", label: "All"},
  // { key: "DRAFT", label: "Draft"},
  { key: "SENT", label: "Sent" },
  { key: "RECURRING", label: "Recurring" },
  { key: "SCHEDULED", label: "Scheduled" },
  { key: "PROCESSING", label: "Processing" },
  { key: "ARCHIVED", label: "Archived" },
];