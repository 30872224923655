import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import "../../../css/android-device.css";
import "../../../css/journey-css.css";
import "../../../css/style.css";
import uploadIcon from "../../../images/wigzolite icons/wigzolite-icons/broadcast/upload_icon.svg";
import {
  fetchAllPushTemplates, getOrgCurrency, getShortenUrl, getAllUnpagedWhatsappTemplates
} from "../../../services/GetService";
import { store } from "../../../store/store";
import GalleryModal from "../GalleryModel";
import PhonePreviewWhatsapp from './PhonePreviewWhatsapp';

const SelectMessageTemplate = (props) => {
  const location = useLocation();
  
  const {formData, setFormData} = props;

  const {isValid, setIsValid} = props;

  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const state = store.getState();
  const [broadcastTemplate, setBroadcastTemplate] = useState({
    list: [],
    count: "",
  });


  let placeholders = [
    { "item": "{{name}}", "label": "Customer Name" },
    // { "item": "{{domain_name}}", "label": "Domain Name" },
    { "item": "constantValue", "label": "Constant value" },
  ];
  const [orgCurrency, setOrgCurrency] = useState("");
  
      
      const messageTemplateInitValues = {campaignName:'',selectedTemp:'',selectedTempName:'',replacedAttachment:'',message:'',variables:'',broadcastTemplateUuid:'',updatedAt:'',updatedAt:'',templateType:'',provider:'',status:'', mediaUrl:'',dropdownVars:'',replacedVariables:'',replacedButtonUrl:''};
      
      const [messageTemplate,setMessageTemplate] = useState({
        campaignName:formData?.campaignName||'',
        selectedTemp:formData?.selectedTemp||'',
        selectedTempName:formData?.selectedTempName||'',
        replacedAttachment:formData?.replacedAttachment||'',
        message:formData?.message||'',
        variables:formData?.variables,
        broadcastTemplateUuid:formData?.broadcastTemplateUuid,
        updatedAt:formData?.updatedAt,
        templateType:formData?.templateType,
        provider:formData?.provider,
        status:formData?.status, 
        mediaUrl:formData?.mediaUrl,
        dropdownVars:formData?.dropdownVars,
        replacedVariables:formData?.replacedVariables,
        replacedButtonUrl: formData?.replacedButtonUrl
  });

  const [validationError, setValidationError] = useState({
      replacedAttachmentError:'',
      replacedButtonUrlError:[],
  })

  
  const handleChange = (e) => {
    setMessageTemplate({...messageTemplate,[e.target.name]: e.target.value});
    setFormData({...formData, ...messageTemplate, [e.target.name]:e.target.value});
    // checkFieldValidationError(e.target.name,e.target.value);
  }
  
  const [showModal, setShowModal] = useState(false);
  const [attachments, setAttachments] = useState(
    props.formData.replacedAttachment ? props.formData.replacedAttachment : ""
  );
  // const [buttons, setButtons] = useState(
  //   JSON.parse(formData.replacedButtonUrl).length > 0 ? JSON.parse(formData.replacedButtonUrl) : []
  // );
  const [selectedTemplateFooter, setSelectedTemplateFooter] = useState('');

  const [persistedVariables, setPersistedVariables] = useState(
    _.isEmpty(formData.variables)
      ? "[]"
      : Object.values(JSON.parse(formData.variables))
  );
  const [dropdownVars, setDropdownVars] = useState(_.isEmpty(props.formData.dropdownVars) ? {} : props.formData.dropdownVars);
  let replacedVars = {};
  let replacedButtons = {};
  // let changedMsg = formik.values.message;
  const handleTemplateChange = async (e) => {
    let templateUuid = e?.target?.value || formData.selectedTemp;
    let selectedTemplate = e.status === 'selectedTemplateData' ? e.selectedTemp : _.find(broadcastTemplate?.list, {
      uuid: templateUuid,
    });

    let selectedTemplateVariables = JSON.parse(selectedTemplate.variables);
    // let selectedTemplateButtons = JSON.parse(selectedTemplate?.buttons);
      
    setAttachments(selectedTemplate?.attachments);
      
    let dropdownVarsValue = {};
    !!selectedTemplate.variables && !!selectedTemplateVariables && selectedTemplateVariables.forEach((e, i) => {
      replacedVars[e] = e;
      dropdownVarsValue[i] = '';
    });
    
    setDropdownVars({ ...dropdownVarsValue });
    replacedButtons = selectedTemplate?.buttons;
    
    let replaceBtnUrlError = [];
    if(replacedButtons) {
      JSON.parse(replacedButtons).forEach((btn) => {
        replaceBtnUrlError.push('');
      })
    }
    setValidationError({...validationError,replacedButtonUrlError:replaceBtnUrlError});
    setFormData({...formData, replacedVariables: '{}', replacedButtonUrl: '[]'});
    
    setMessageTemplate(
      {...formData,
        selectedTemp:selectedTemplate.uuid, 
        selectedTempName:selectedTemplate.templateName,
        message:selectedTemplate.message,
        variables: selectedTemplate.variables,
        replacedAttachment:selectedTemplate.replacedAttachment,
        broadcastTemplateUuid: selectedTemplate.uuid,
        updatedAt: selectedTemplate.updatedAt,
        templateType:selectedTemplate.provider,
        provider:selectedTemplate.status,
        status:selectedTemplate.status,
        language: selectedTemplate.language,
        mediaUrl: selectedTemplate.attachments,
        // dropdownVars:{...dropdownVars},
        dropdownVars:{...dropdownVarsValue},
        replacedVariables: JSON.stringify({...replacedVars}),
        replacedButtonUrl: selectedTemplate.buttons
      })


    setSelectedTemplateFooter(selectedTemplate.footer);
    setPersistedVariables(!!selectedTemplate?.variables ? selectedTemplateVariables : persistedVariables);
  }
    // Dropdown Vars Value Change
    let dropdownVarsValueChange = (e, i) => {
      let dropdownVarsValue = dropdownVars;
      let targetedValue = e.target.value.trim();
      dropdownVarsValue[i.props.dataindex] = targetedValue;
      document.getElementById(`customVarTextField${i.props.dataindex}`).value = targetedValue === 'constantValue' ? '' : targetedValue.replace("name","first_name");
      setDropdownVars(dropdownVarsValue);
      if(e.target.value === 'constantValue') {
        if(messageTemplate) {
          replacedVars = JSON.parse(messageTemplate.replacedVariables);
        }
        replacedVars[`{{$${i.props.dataindex+1}}}`] = '';
      }
      setMessageTemplate({...messageTemplate,replacedVariables:JSON.stringify(replacedVars),dropdownVars:dropdownVarsValue});
      if (e.target.value.indexOf('{{') >= 0) {
        customVar(e);
      }
  };
  
  //replacing variables
  let customVar = (e) => {
    let targetedValue = e.target.value;
    let index = parseInt(e.nativeEvent.target.getAttribute('dataindex'));
    replacedVars = JSON.parse(messageTemplate.replacedVariables);
    replacedVars[`{{$${index + 1}}}`] = targetedValue;
    setMessageTemplate({...messageTemplate,replacedVariables: JSON.stringify(replacedVars)});
    // document.getElementById(`customVarTextField${index}`).value = targetedValue;
  };


  let handleButtonUrl = (e) => {
    let replacedButtons = JSON.parse(messageTemplate?.replacedButtonUrl);
    let index = parseInt(e.target.dataset.index);
    let btnUrl = e.target.value.trim();
    replacedButtons[index].url = btnUrl;
    setMessageTemplate({...messageTemplate,replacedButtonUrl:JSON.stringify(replacedButtons)});
    // document.getElementById(`btn${index}`).value = btnUrl;
  };


  let handleAttachments = (e) => {
    let replacedAttachment = !!e.target ? e.target.value.trim() : e.trim();
    setMessageTemplate({...messageTemplate,replacedAttachment:replacedAttachment});
  };

  const handleGallerySelection = (obj) => {
    handleAttachments(obj.mediaFileUrl);
  };
  function hideGalleryModal(value) {
    !value && setShowModal(false);
  }
  function fetchAllTemplates() {
    getAllUnpagedWhatsappTemplates(state.auth.org.org_token, 'broadcast').then(
      (response) => {
        setBroadcastTemplate({
          list: response.data.templates.sort((obj, i) => i.updatedAt.localeCompare(obj.updatedAt)),
          count: response?.data?.count,
        });
      }
    );
    if (props.selectedChannel === "push") {
      fetchAllPushTemplates(state.auth.org.org_token).then((response) => {
        setBroadcastTemplate(response?.data);
      });
    }
  }

  function replacedVariableValue(i) {
    let replacedVariableValue = ((!_.isEmpty(JSON.parse(formData.replacedVariables)) && JSON.parse(formData.replacedVariables)[`{{$${i + 1}}}`]) || (!!messageTemplate.replacedVariables && JSON.parse(messageTemplate.replacedVariables)[`{{$${i + 1}}}`]));
    return replacedVariableValue;
  }
  
  function replacedButtonUrlValue(i) {
    let replacedButtonUrlValue = ((JSON.parse(formData?.replacedButtonUrl).length > 0 && JSON.parse(formData?.replacedButtonUrl)[i]?.url) || (!!messageTemplate.replacedButtonUrl && JSON.parse(messageTemplate?.replacedButtonUrl)[i]?.url));
    return replacedButtonUrlValue;
  }
  function dropdownVarsValue(i) {
    let dropdownVarsValue = ((messageTemplate.hasOwnProperty(dropdownVars) && !! messageTemplate.dropdownVars[i]) ? messageTemplate.dropdownVars[i] :  messageTemplate.dropdownVars[i]);
    return dropdownVarsValue === undefined ? '' : dropdownVarsValue;
  }
  useEffect(() => {
    fetchAllTemplates();
    getOrgCurrency(state.auth.org.org_token).then((res) => {
      setOrgCurrency(res.data.orgCurrency);
    });
    props.handleButtonState(isValid);
  }, []);


  useEffect(() => {
    let validBtnUrl = true;
    let validBtnUrlError = [];
    if(messageTemplate.replacedButtonUrl && JSON.parse(messageTemplate.replacedButtonUrl).length>0) {
      JSON.parse(messageTemplate.replacedButtonUrl).forEach((btn,index) => {
        validBtnUrlError[index] = false;
        validBtnUrlError[index] = !!btn.url && btn.url!=='{{buttonUrl}}' &&
                      btn?.url?.trim().match(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/) ? false : true;
        validBtnUrl = validBtnUrl && !validBtnUrlError[index];
      }); 
    }

    let validVariableValue = true;
    
    if(messageTemplate.replacedVariables) {
      Object.entries(JSON.parse(messageTemplate.replacedVariables)).forEach(([key, value]) => {
        if(!value || key === value) {
          validVariableValue = false;
        } 
      });
    }


    let validImageUrl = !!attachments ? false : true;
    if(messageTemplate.replacedAttachment) {
      validImageUrl = !!messageTemplate.replacedAttachment &&
                      messageTemplate.replacedAttachment.trim().match(/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg))/)
    }

    setValidationError({...validationError,replacedButtonUrlError: validBtnUrlError, replacedAttachmentError: validImageUrl });
    // console.log("here1111", Boolean(validImageUrl), validBtnUrl, validVariableValue);

    if (
      (!!messageTemplate.campaignName) &&
      (!!messageTemplate.selectedTemp) &&
      (validImageUrl) && 
      (validBtnUrl) && 
      (validVariableValue)
    ) { 
      setFormData({...formData,...messageTemplate});
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [messageTemplate]);

  const saveToDraft = () => {

  }

  return (
    <>
      <section>
        <div className="broadcast-channels-container d-flex">
          <div
            style={{ paddingTop: "0rem" }}
            className="message-left-container custom-selectable-dropdown">
            <form id={"Message"}>
              <div className="custom-card-container">
                <h3 className="custom-card-heading">Message</h3>
                <div className="custom-card-body">
                  <div
                    className="validate-redux-form broadcast-message-container"
                    style={{ textAlign: "left" }}
                  >
                    <div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="waForm-subheading">Campaign Name</h4>
                        <div style={{ width: "63%" }}>
                          <TextField
                            variant="outlined"
                            name="campaignName"
                            autoComplete="off"
                            className="form-control"
                            label=""
                            placeholder="Enter Campaign Name"
                            id="campaignname"
                            inputProps={{ maxLength: 50 }}
                            onChange={handleChange}
                            style={{ marginTop: "14px" }}
                            value={messageTemplate?.campaignName}
                            error={!messageTemplate?.campaignName}
                          />
                        </div>
                        <p
                          id="ErrorMessage"
                          style={{
                            display: "none",
                            color: "red",
                            fontSize: "10px",
                            marginTop: "15px",
                          }}
                        >
                          Campaign name should not contain special
                          characters except " - " and " _ "
                        </p>
                      </div>
                      <div
                        className=""
                        style={{
                          display:
                            formData.selectedChannel !== "sms"
                              ? "flex"
                              : "none",
                          width:
                            formData.selectedChannel !== "sms"
                              ? "100%"
                              : "",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="waForm-subheading">
                          Select Template
                        </h4>
                        <div className="d-flex flex-column align-items-end" style={{ width: '63%' }}>
                          <FormControl style={{ width: '100%' }}>
                            <Select
                              displayEmpty
                              variant="outlined"
                              style={{ marginTop: "14px" }}
                              name="selectedTemp"
                              value={messageTemplate.selectedTemp}
                              renderValue={!!messageTemplate.selectedTemp ? undefined : () => "Select Template"}
                              onChange={(e) => handleTemplateChange(e)}
                              // error={formik.touched.selectedTemp && Boolean(formik.errors.selectedTemp)}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              {broadcastTemplate.list.length <= 0 ? (
                                <p style={{ margin: "0 25px" }}>
                                  {" "}
                                  'My List' is empty, please add templates from 'Template Gallery'.{" "}
                                </p>
                              ) : (
                                !!broadcastTemplate.list &&
                                broadcastTemplate.list.map((templates, i) => {
                                  return (
                                    <MenuItem
                                      key={templates?.uuid}
                                      value={templates?.uuid}
                                    >
                                      {templates?.templateName}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                          <div className="d-flex align-items-baseline">
                            <a href="#a" className="wigzo-hyperlink" onClick={() => !!searchParams.has('wib') ? history.push(`/create-whatsapp?wib=1`) : history.push('/create-whatsapp')}>
                              Create new Template</a> &#160;
                            or
                            &#160; <a href="#b" className="wigzo-hyperlink" onClick={() => !!searchParams.has('wib') ? history.push(`/template-gallery?wib=1`) : history.push('/template-gallery')}>
                              Template Gallery</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          props.formData.selectedChannel != "sms"
                            ? "block"
                            : "none",
                      }}
                    >
                      {!!attachments ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                          }}
                        >
                          <h6
                            className="waForm-subheading"
                            style={{ marginRight: "25px" }}
                          >
                            Image URL
                          </h6>
                          <div style={{ width: '63%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <TextField
                              variant="outlined"
                              autoComplete="off"
                              className="form-control"
                              label=""
                              id="replacedAttachment"
                              name="replacedAttachment"
                              value={messageTemplate?.replacedAttachment?.search('}}') >= 0 ? '' : messageTemplate?.replacedAttachment}
                              // key={replacedAttachmentValue()}
                              placeholder="Enter URL"
                              style={{
                                width: "100%",
                              }}
                              onChange={handleChange}
                              // onChangeCapture={formik.handleBlur('replacedAttachment')}
                              error={!validationError.replacedAttachmentError}
                              required
                            />
                            <div className="d-flex justify-content-between align-items-baseline" style={{ width: '100%' }}>
                              {/* <span style={{ fontSize: '12px', color: (!!formik.touched.replacedAttachment && !!formik.errors.replacedAttachment) ? 'red' : '#7e7e7e' }}>Acceptable image formats: PNG, JPG, JPEG</span> */}
                              <a className="wigzo-hyperlink" onClick={() => setShowModal(true)}>
                                <img src={uploadIcon} height="18"></img>
                                Upload Image
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {messageTemplate?.replacedButtonUrl && JSON.parse(messageTemplate?.replacedButtonUrl)?.length > 0 &&
                        JSON.parse(messageTemplate?.replacedButtonUrl)?.map((e, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <h6
                                className="waForm-subheading"
                                style={{

                                  marginRight: "25px",
                                }}
                              >
                                {" "}
                                Button URL {i+1}
                              </h6>
                              <div style={{ width: "63%" }}>
                                <TextField
                                  key={i}
                                  variant="outlined"
                                  id={`btn${i}`}
                                  inputProps={{ 'data-index': i }}
                                  autoComplete="off"
                                  value={(e?.url === '{{buttonUrl}}' || e?.url === undefined) ? '' : e?.url}
                                  name="replacedButtonUrl"
                                  autoFocus
                                  className="form-control"
                                  label=""
                                  placeholder="Enter URL"
                                  onChange={handleButtonUrl}
                                  // onChangeCapture={_.debounce(formik.handleBlur('replacedButtonUrl'), 100)}
                                  error={validationError?.replacedButtonUrlError[i]}
                                  required
                                />
                              </div>
                            </div>
                          );
                        })}
                      {
                        !!persistedVariables &&
                        persistedVariables.map((e, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <h6
                                className="waForm-subheading"
                                style={{

                                  marginRight: "25px",
                                }}
                              >
                                Variable {i + 1}
                              </h6>
                              <div className="d-flex align-items-center " style={{ width: '63%' }}>
                                <div style={{ position: 'absolute', left: '19.5%' }}>{e}</div>
                                <FormControl style={{ width: '100%' }}>
                                  <Select
                                    displayEmpty
                                    variant="outlined"
                                    className=""
                                    // renderValue={!!dropdownVarsValue(i) ? undefined : () => "Select value"}
                                    renderValue={dropdownVars[i] === '{{name}}' ? () => 'Customer Name' : dropdownVars[i] === 'constantValue' ? () => 'Constant Value' : () => 'Select Variable'}
                                    value={dropdownVars[i] === '{{name}}' ? () => 'Customer Name' : dropdownVars[i] === 'constantValue' ? () => 'Constant Value' : () => ''}
                                    placeholder="Select Variable"
                                    onChange={(e, i) => dropdownVarsValueChange(e, i)}
                                    required
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                  >
                                    {
                                      placeholders.map((key) => {
                                        return (
                                          <MenuItem key={key.item} value={key.item} dataindex={i} dropdownvar={key.item}>{key.label}</MenuItem>
                                        );
                                      })
                                    }
                                  </Select>
                                </FormControl>
                                <TextField
                                  variant="outlined"
                                  type={'text'}
                                  autoFocus
                                  autoComplete="off"
                                  id={`customVarTextField${i}`}
                                  className="form-control "
                                  label=""
                                  style={{ width: "100%", marginLeft: "10px" }}
                                  disabled={dropdownVarsValue(i).length > 0 ? !(dropdownVarsValue(i).indexOf('{{') < 0) : true}
                                  defaultValue={replacedVariableValue(i) !== undefined && replacedVariableValue(i).indexOf('{{$') < 0 ? replacedVariableValue(i) : ''}
                                  value={replacedVariableValue(i) !== undefined && replacedVariableValue(i).indexOf('{{$') < 0 ? replacedVariableValue(i) : ''}
                                  key={i}
                                  inputProps={{ 'dataindex': i, maxLength: 50 }}
                                  placeholder="Constant value"
                                  onChange={customVar}
                                  error={!!replacedVariableValue(i) ? false:true}
                                  required={dropdownVarsValue(i).length > 0 && dropdownVarsValue(i).search('}}') < 0}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="message-right-container col-md-6" style={{ marginTop: '50px' }} >

            <PhonePreviewWhatsapp message={messageTemplate.message} attachment={messageTemplate?.replacedAttachment?.search('http') >= 0 ? messageTemplate?.replacedAttachment : !messageTemplate?.replacedAttachment ? '' : 'https://media.shipconvert.com/SRConvert/imageplaceholder.jpeg'} replacedButtonUrl={messageTemplate.replacedButtonUrl} org_name={state.auth.org.org_name} footer={selectedTemplateFooter} />
          </div>
        </div>

        <GalleryModal handleSelection={(e) => setTimeout(handleGallerySelection(e), 0)} handleGalleryClose={hideGalleryModal} showModal={showModal}></GalleryModal>
      </section>
    </>
  );
};

export default SelectMessageTemplate;
