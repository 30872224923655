import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { ExpandMoreIcon } from '../BroadcastV2/Svgs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import "../../../css/Modal.css";
import { uploadWhatsappCSV, validateCsv } from '../../../services/GetService';


const CsvUploadModal = (props) => {
    const { showModal, setShowModal, fetchUploadedCsvList } = props;
    const [progress, setProgress] = React.useState(0);
    const [csvFileUploaded, setCsvFileUploaded] = useState(false);
    const initialFileUpload = { file: '', fileName: '', uuid: '', uploadStatus: 'not-uploaded', error: false, errorFile: '', totalRows: 0, errorRows: 0, headers: [], errorMessage: '' };
    const [csvFileUpload, setCsvFileUpload] = useState(initialFileUpload);
    const [validateDisabled,setValidateDisabled]= useState(true);
    const { setAudience, formData, setFormData } = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }));

    const classes = useStyles();
    let uploadCsvBtn = {
        cursor: "pointer",
        border: "1px solid #4C02FF",
        background: "#4C02FF",
        borderWidth: "0px",
        color: "#fff",
        borderRadius: "50%",
        fontSize: "0.875rem",
        fontWeight: "600",
        padding: "0.5rem",
    };

    let uploadBtn = {
        cursor: "pointer",
        border: "1px solid #4C02FF",
        background: "#4C02FF",
        borderWidth: "0px",
        color: "#fff",
        borderRadius: "5px",
        fontSize: "0.875rem",
        fontWeight: "600",
        padding: "0.5rem 3rem",
        float: "right",
    };

    let disabledBtn = {
        ...uploadBtn,
        border: "1px solid #c4c4c4",
        background: "#C4C4C4",
        color: "#fff",
    };

    const state = useSelector((state) => state);
    const uploadCsv = () => {
        console.log("uploadCSV");
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            handleFileChange(files[0]);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const addCsv = () => {
        fetchUploadedCsvList();
    };

    const handleFileChange = (event) => {
        const file = event instanceof Blob ? event : event.target.files[0];
        const formData = new FormData();
        if (file) {
            setCsvFileUpload({ ...csvFileUpload, file: file, fileName: file.name, uploadStatus: 'uploading' });
            formData.append("file", file || csvFileUpload.file);
            uploadWhatsappCSV(formData, state.auth.org.org_token).then((response) => {
                setCsvFileUpload({ ...csvFileUpload, file: response?.data?.url, fileName: response?.data?.fileName, headers: response?.data?.csvHeader, uploadStatus: 'uploaded', totalRows: response?.data?.dataCount, error: false, uuid: response?.data?.uuid });
                setCsvFileUploaded(true);
                setValidateDisabled(false)
            })
            .catch((error) => {
                setValidateDisabled(true);
                setCsvFileUpload({ ...csvFileUpload, uploadStatus: 'uploaded', error: true, errorMessage:error?.response?.data?.error});
            });
        }
    };

    const validateCSV = () => {
        console.log("Validating CSV");
        setCsvFileUpload({ ...csvFileUpload, uploadStatus: 'validating' });
        let payload = {
            uuid: csvFileUpload?.uuid,
            to_be_validated: ["phone"],
            channel: "whatsapp"
        };
        validateCsv(payload, state.auth.org.org_token).then((response) => {
            console.log("Validate CSV Response", response);
            if (response?.data?.hasOwnProperty('failed_rows') && response?.data?.failed_rows > 0) {
                setCsvFileUpload({ ...csvFileUpload, uploadStatus: 'validated', error: true, totalRows: response?.data?.total_rows, errorRows: response?.data?.failed_rows, errorFile: response?.data?.url });
            }
            if (response?.data?.hasOwnProperty('failed_rows') && response?.data?.failed_rows === 0) {
                setCsvFileUpload({ ...csvFileUpload, uploadStatus: 'validated', error: false, totalRows: response?.data?.total_rows, errorRows: response?.data?.failed_rows });
            }
            console.log(response?.data?.total_rows - response?.data?.failed_rows);
            let audience = {
                segmentType: "CSV",
                selectedSegment: csvFileUpload.file,
                selectedSegmentName: csvFileUpload.fileName,
                estimateReach: (response?.data?.extraData?.counts?.estimated_reach),
            };
            setAudience(audience);
            audience = {
                ...audience,
                headers: csvFileUpload.headers.join(","),
                communicationIndex: null
            };
            setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [audience] }, onlyCsv: true });
            console.log("Audience", audience);
        })
        .catch((error) => {
            setCsvFileUpload({ ...csvFileUpload, uploadStatus: 'validated', error: true, errorFile: error?.response?.data?.url, errorMessage: error.response.data.error, uuid: error?.response?.data?.uuid });
        });
    };

    const reUpload = () => {
        setCsvFileUpload({ ...initialFileUpload });
        let initAudience = { selectedSegment: '', segmentType: '', estimateReach: 0, selectedSegmentName: '' };
        setAudience(initAudience);
        setValidateDisabled(true);
        setFormData({ ...formData, targetedAudience: { ...formData.targetedAudience, includes: [initAudience] }});
    };

    const fileInputRef = useRef(null);

    // const reUploadFile = () => {

    // }

    return <>
        <div className='mt-4'>
            {/* <div className='d-flex justify-content-center pos-rel'> */}
            {/* <h3>Add Audience</h3> */}
            {/* <div className='d-flex close-icon'>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => setShowModal(false)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" viewBox="0 0 20 20">
                                <title>close</title>
                                <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"/>
                            </svg>
                        </Button>
                    </div> */}
            {/* </div> */}
            <div className='p-3'>
                <h4>Upload File</h4>
                {
                    (csvFileUpload.uploadStatus === 'uploading' || csvFileUpload.uploadStatus === 'validating') &&
                    <div className='d-flex flex-column align-items-center upload-csv p-4 mt-3'>
                        <div className={classes.root}>
                            <CircularProgress color="primary" />
                        </div>
                        <h4 className='mt-2'> {csvFileUpload.uploadStatus === 'uploading' ? 'Uploading...' : 'Validating...'} </h4>
                    </div>
                }
                {
                    (csvFileUpload.uploadStatus === 'not-uploaded' || csvFileUpload.uploadStatus === 're-upload') &&
                    <div onClick={uploadCsv} onDragOver={handleDragOver} onDrop={handleDrop} className='d-flex flex-column justify-content-center align-items-center upload-csv p-4 mt-3 cursor'>
                        <input
                            id="csvUpload"
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <button type="button" style={uploadCsvBtn}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                                <title>plus</title>
                                <path fill="#fff" d="M5 13h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1z" />
                            </svg>
                        </button>
                        <h4 className='mt-2 color-50c878 fw-600'>Select a CSV file to upload</h4>
                        <h6 className='color-61656d'>or drag and drop it here</h6>
                    </div>
                }
                {
                    csvFileUpload?.uploadStatus === 'validated' && csvFileUpload.error &&
                    <div>
                        {/* <div>
                                {csvFileUpload?.fileName &&  <span> {csvFileUpload.fileName} </span>}
                                <a className='remove-file' onClick={()=>reUpload}> Remove File </a>
                            </div> */}
                        <div className='d-flex flex-column align-items-center upload-csv p-4 mt-3'>
                            {!csvFileUpload.errorMessage && <p>
                                {csvFileUpload.errorRows} of {csvFileUpload.totalRows} contains errors.Please download and re-upload the error file,
                                or move further with the existing buyer data.
                            </p>
                            }
                            {csvFileUpload.errorMessage &&
                                <p>
                                    {csvFileUpload.errorMessage}
                                </p>
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="52" height="52" viewBox="0 0 32 32">
                                <title>warning</title>
                                <path fill="#FF6961" d="M30.555 25.219l-12.519-21.436c-1.044-1.044-2.738-1.044-3.782 0l-12.52 21.436c-1.044 1.043-1.044 2.736 0 3.781h28.82c1.046-1.045 1.046-2.738 0.001-3.781zM14.992 11.478c0-0.829 0.672-1.5 1.5-1.5s1.5 0.671 1.5 1.5v7c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-7zM16.501 24.986c-0.828 0-1.5-0.67-1.5-1.5 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.83-0.672 1.5-1.5 1.5z" />
                            </svg>
                            <a href={csvFileUpload?.errorFile} target="_blank" className='mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#FF6961" />
                                    <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#FF6961" />
                                </svg>
                                <span className='ml-1 color-FF6961'>Download Error File</span>
                            </a>
                            <Button
                                style={uploadBtn} className='mt-3' onClick={reUpload}
                            >
                                Upload Again
                            </Button>
                        </div>
                    </div>
                }
                {
                    csvFileUpload.uploadStatus === 'uploaded' && csvFileUpload.error && (
                        <div>
                             <div className='d-flex flex-column align-items-center upload-csv p-4 mt-3'>
                            
                                {csvFileUpload.error &&
                                    <p className='color-bf4343'>
                                        {csvFileUpload.errorMessage}
                                    </p>
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="52" height="52" viewBox="0 0 32 32">
                                    <title>warning</title>
                                    <path fill="#FF6961" d="M30.555 25.219l-12.519-21.436c-1.044-1.044-2.738-1.044-3.782 0l-12.52 21.436c-1.044 1.043-1.044 2.736 0 3.781h28.82c1.046-1.045 1.046-2.738 0.001-3.781zM14.992 11.478c0-0.829 0.672-1.5 1.5-1.5s1.5 0.671 1.5 1.5v7c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-7zM16.501 24.986c-0.828 0-1.5-0.67-1.5-1.5 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.83-0.672 1.5-1.5 1.5z" />
                                </svg>
                                {/* <a href={csvFileUpload?.errorFile} target="_blank" className='mt-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                        <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#FF6961" />
                                        <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#FF6961" />
                                    </svg>
                                    <span className='ml-1 color-FF6961'>Download Error File</span>
                                </a> */}
                                <Button
                                    style={uploadBtn} className='mt-3' onClick={reUpload}
                                >
                                    Upload Again
                                </Button>
                            </div>
                        </div>
                    )
                }
                {
                    csvFileUpload.uploadStatus === 'uploaded' && !csvFileUpload.error &&
                    <div>
                        {/* <div>
                                {csvFileUpload?.fileName &&  <span> {csvFileUpload.fileName} </span>}
                                <a className='remove-file' onClick={reUpload}> Remove File </a>
                            </div> */}
                        <div className='d-flex flex-column justify-content-center upload-csv p-4 mt-3'>
                            {csvFileUpload?.fileName &&
                                <div className='d-flex align-items-center justify-content-between b-ddd p-2'>
                                    <h5> {csvFileUpload?.fileName}</h5>
                                    <a className='remove-file' onClick={reUpload}> Remove File </a>
                                </div>
                            }
                            <div className='d-flex justify-content-center  flex-column align-items-center mt-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 22 22">
                                    <g data-name="Group 21866" transform="translate(-101 -116)">
                                        <circle data-name="Ellipse 685" cx="11" cy="11" r="11" transform="translate(101 116)" style={{ fill: "#008e27" }} />
                                        <g data-name="Group 21865">
                                            <path data-name="Path 1069" d="m.982 1.6 3.473 2.8" transform="translate(106.097 126.201)" style={{ fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "2px" }} />
                                            <path data-name="Line 17" transform="translate(110.984 123.399)" style={{ strokeLinejoin: 'round', fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "2px" }} d="M0 6.927 5.937 0" />
                                        </g>
                                    </g>
                                </svg>
                                <h4 className='mt-2'>{csvFileUpload.totalRows} Records Uploaded Successfully</h4>
                            </div>
                        </div>
                    </div>
                }
                {
                    csvFileUpload.uploadStatus === 'validated' && !csvFileUpload.error &&
                    <div className='d-flex flex-column justify-content-center upload-csv p-4 mt-3'>
                        {csvFileUpload?.fileName &&
                            <div className='d-flex align-items-center justify-content-between b-ddd p-2'>
                                <h5> {csvFileUpload?.fileName}</h5>
                                <a className='remove-file' onClick={reUpload}> Remove File </a>
                            </div>
                        }
                        <div className='d-flex justify-content-center  flex-column align-items-center mt-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 22 22">
                                <g data-name="Group 21866" transform="translate(-101 -116)">
                                    <circle data-name="Ellipse 685" cx="11" cy="11" r="11" transform="translate(101 116)" style={{ fill: "#008e27" }} />
                                    <g data-name="Group 21865">
                                        <path data-name="Path 1069" d="m.982 1.6 3.473 2.8" transform="translate(106.097 126.201)" style={{ fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "2px" }} />
                                        <path data-name="Line 17" transform="translate(110.984 123.399)" style={{ strokeLinejoin: 'round', fill: "none", stroke: "#fff", strokeLinecap: "round", strokeWidth: "2px" }} d="M0 6.927 5.937 0" />
                                    </g>
                                </g>
                            </svg>
                            <h4 className='mt-2'>{csvFileUpload.totalRows} Records Validated Successfully</h4>
                        </div>
                    </div>
                }
                <div className='d-flex justify-content-end mt-3'>
                    <a href="https://sr-cdn-mum.s3.ap-south-1.amazonaws.com/csv/shiprocket/New-file+(1).csv" target="_blank" download>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                            <path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#000000" />
                            <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#000000" />
                        </svg>
                        <span className='ml-1 color-000000'>Download Sample Csv</span>
                    </a>
                </div>
                <div className='mt-3'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={ExpandMoreIcon}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Instructions to upload CSV</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <p>First Name and Phone number is mandatory (Fields with * are mandatory fields.)</p>
                                <p>The uploaded file should be with the headers.</p>
                                <p>Phone number should be integer of length 10.</p>
                                <p>Name should not be alphanumeric.</p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='d-flex justify-content-end mt-4'>
                    <button  onClick={validateCSV} disabled={validateDisabled}
                    className={validateDisabled?'disabled-button':'validate-button'}>
                        Validate
                    </button>
                </div>
            </div>
        </div>
    </>;
};

export default CsvUploadModal;