import React, { Fragment, useEffect } from "react";
// import { ReactReduxContext } from "react-redux";
import { useLocation } from "react-router-dom";
// import { useContext } from "react";
import "../../../../css/graphData.css";
import DashboardAnalytics from "./Dashboard/DashboardAnalytics";
import { createJobForSrBi } from "../../../../services/GetService";

const Home = () => {
   document.title = "SR Convert Dashboard";
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   // let { store } = useContext(ReactReduxContext);
   // let state = store.getState();
   const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));

   useEffect(() => {
      createJobForSrBi(orgDetails.org_token).then((res) => {
         // console.log(res);
      });
   }, []);

   return (
      <Fragment>
         <div className="row content-body" style={!!searchParams.has('wib') ? { marginLeft: '1.2rem', paddingTop: '2rem' } : {}}>
            <div className="dashboard_bar">
               {/* <h3 style={{ color: "black", textTransform: 'capitalize' }}> Welcome, {userDetails.user_name} 👋</h3> */}
               {/* <p style={{ color: "#4C02FF" }}>Your current status and analytics are here</p> */}
               <h3 style={{ textTransform: 'capitalize' }}> Dashboard</h3>
            </div>
            <DashboardAnalytics />
            {/* <DashboardData /> */}
         </div>
      </Fragment>
   );

};

export default Home;
